import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { format, subDays } from "date-fns";

const generateLast14DaysData = () => {
  const today = new Date();
  const data = [];

  for (let i = 9; i >= 0; i--) {
    const date = subDays(today, i);
    data.push({
      date: format(date, "yyyy-MM-dd"),
      uv: Math.floor(Math.random() * 5000),
      pv: Math.floor(Math.random() * 5000),
    });
  }

  return data;
};

const data = generateLast14DaysData();

const IncomeAndExpenditure = ({ transactions }) => {
  const amountReceived = transactions.map((amount) => {
    return amount.amountReceived;
  });

  const amountSent = transactions.map((amount) => {
    return amount.amountSent;
  });
  const allAmounts = [...amountReceived, ...amountSent];
  const maxAmount = Math.max(...allAmounts);

  console.log(maxAmount);

  return (
    <ResponsiveContainer height={"90%"} width={"100%"}>
      <BarChart width={"100%"} height={"100%"} data={data}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="date"
          tickFormatter={(dateStr) => format(new Date(dateStr), "MMM dd")}
          tickLine={false}
        />
        <YAxis tickLine={false} axisLine={false} />
        <Tooltip />
        <Bar dataKey="pv" fill="#192851" barSize={24} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default IncomeAndExpenditure;
