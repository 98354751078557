import React, { useState, useEffect, useRef } from "react";
import Header from "../components/layout/Header";
import SideBar from "../components/layout/SideBar";
import SEO from "../components/utils/Seo";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SelectInput from "../components/common/SelectInput";
import { rate } from "../store/actions/exchange/rate";
import TextInput from "../components/common/TextInput";
import Button from "../components/common/Button";
import { formHandler } from "../utils/formhandler";
import { FormikWrapper } from "../components/features/exchange/FormikWrapper";

function Exchange() {
  const [cards, setCards] = useState([{}]);
  const cardContainerRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currencyOptions = [
    { value: "Currency Denomination", label: "Currency Denomination" },
    { value: "USD", label: "USD" },
    { value: "NGN", label: "Naira" },
    { value: "EUR", label: "Euro" },
    { value: "GBP", label: "British Pound" },
  ];

  const addCard = () => {
    setCards([...cards, {}]);
  };

  const removeCard = (index) => {
    setCards(cards.filter((_, cardIndex) => cardIndex !== index));
  };

  useEffect(() => {
    if (
      cards.length > 3 ||
      cardContainerRef.current.scrollHeight > window.innerHeight
    ) {
      cardContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [cards]);

  const handleFormSubmit = (values, { setSubmitting }, cardIndex) => {
    const data = [
      {
        currency: values.from,
        quotes: {
          [`${values.from}${values.to}`]: values.rate,
        },
      },
    ];

    dispatch(rate({ navigate, data, setSubmitting }));
  };

  return (
    <div className="font-mons">
      <SEO
        title={"Exchange Rates"}
        description={""}
        name={"Imperial Finance"}
        type={"article"}
      />
      <Header />

      <div className="bg-[#FFFFFF] pt-[72px] font-mons flex min-h-screen">
        <SideBar />
        <div className="flex-1 max-w-[100vw] overflow-x-auto pt-4 px-4 md:px-10 bg-[#F1F1F1] relative w-full">
          <div
            className="flex flex-col w-full lg:max-w-[681px]"
            ref={cardContainerRef}
          >
            <div className="flex flex-col md:flex-row justify-between">
              <h1 className="font-semibold text-[20px] leading-[24.38px] tracking-[-0.01em] text-[#000000] mb-2">
                Exchange Rates
              </h1>
              <button
                onClick={addCard}
                className="bg-[#192851] text-[#ffffff] px-2 py-2 rounded-md flex items-center justify-center w-full md:w-[160px] h-[45px] mt-2 md:mt-0"
              >
                + Add Rates
              </button>
            </div>

            {cards.map((card, index) => (
              <div
                key={index}
                className="bg-[#FFF] mt-8 border-[#D1D1D1] w-full lg:w-[681px] lg:h-[307px] h-[600px] rounded-[15px] border-[0.8px] border-gray-400 px-5 py-5"
              >
                <FormikWrapper
                  index={index}
                  handleFormSubmit={handleFormSubmit}
                  currencyOptions={currencyOptions}
                  removeCard={removeCard}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Exchange;
