import React, { useEffect } from "react";
import Header from "../components/layout/Header";
import SEO from "../components/utils/Seo";
import LoginMain from "../components/login/LoginMain";

const Login = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="font-mons">
      <SEO
        title={"Login to Imperial Finance"}
        description={""}
        name={"Imperial Finance"}
        type={"article"}
      />
      <Header />
      <div className="font-mons bg-[url('./assets/Images/auth-background.png')] bg-cover h-screen flex flex-col justify-center items-center w-full pt-14">
        <LoginMain />
      </div>
    </div>
  );
};

export default Login;
