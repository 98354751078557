import { useFormik } from "formik";
import SelectInput from "../../common/SelectInput";
import TextInput from "../../common/TextInput";
import { formHandler } from "../../../utils/formhandler";
import Button from "../../common/Button";
import { rateSchema } from "../../../utils/validationSchemas";

export const FormikWrapper = ({
  index,
  handleFormSubmit,
  currencyOptions,
  removeCard,
}) => {
  const form = useFormik({
    initialValues: {
      from: "",
      to: "",
      rate: "",
    },
    validationSchema: rateSchema,
    onSubmit: (values, { setSubmitting }) =>
      handleFormSubmit(values, { setSubmitting }, index),
  });

  return (
    <>
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="w-full md:w-auto">
          <h4 className="text-[#000000] mb-2">From currency</h4>
          <SelectInput
            {...formHandler("from", form)}
            options={currencyOptions}
            mainClasses={"w-full md:w-[251px]"}
          />
        </div>
        <div className="w-full md:w-auto mt-4 md:mt-0">
          <img
            src={require("../../../assets/Icons/arrow.png")}
            className="w-[47px] object-contain mx-auto md:mx-0 mt-6"
            alt="wallet"
          />
        </div>
        <div className="w-full md:w-auto mt-4 md:mt-0">
          <h4 className="text-[#000000] mb-2">To currency</h4>
          <SelectInput
            {...formHandler("to", form)}
            options={currencyOptions}
            mainClasses={"w-full md:w-[251px]"}
          />
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center justify-between mt-4 w-full lg:max-w-[523px]">
        <div className="w-full md:w-auto md:mb-0">
          <h4 className="text-[#000000]">Conversion value</h4>
          <TextInput
            placeholder="currency"
            {...formHandler("rate", form)}
            type={"number"}
            exchange
            mainClasses={"w-full md:w-[120px] h-[32px] rounded-lg"}
          />
        </div>

        <div className="flex items-center mt-10 text-[#000000]">=</div>

        <div className="w-full md:w-auto md:mb-0">
          <h4 className="text-[#000000]">Conversion value</h4>
          <TextInput
            placeholder="currency"
            {...formHandler("rate2", form)}
            type={"text"}
            exchange
            mainClasses={"w-full md:w-[120px] h-[32px] rounded-lg"}
          />
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center justify-between mt-4 w-full lg:max-w-[580px] mt-12">
        <div className="flex flex-col md:flex-row items-center w-full md:w-auto ">
          <Button
            title={"Save"}
            bg={"#192851"}
            width="150px"
            onClick={form.handleSubmit}
            loading={form.isSubmitting}
            disabled={!form.isValid || form.isSubmitting}
            mainClasses={
              "flex flex-col md:flex-row items-center w-full md:w-auto text-[#ffffff] "
            }
            textStyles={"group-hover:text-[#192851] "}
          />
          <button
            onClick={() => removeCard(index)}
            className="text-[#000000] px-2 py-2 rounded-md border  md:w-[45px] h-[45px] flex items-center justify-center w-[40px] ml-3"
          >
            <img
              src={require("../../../assets/Icons/trash.png")}
              alt="delete"
            />
          </button>
        </div>
        <div className="mt-4 md:mt-0 text-[#000000] text-[14px] text-center md:text-left ">
          Updated on:
          <br />
          27th June, 2024 at 12:30 pm
        </div>
      </div>
    </>
  );
};
