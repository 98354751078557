import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import React, { useEffect } from "react";
import Header from "../../components/layout/Header";
import SideBar from "../../components/layout/SideBar";
import SEO from "../../components/utils/Seo";
import { Link } from "react-router-dom";
import Button from "../../components/common/Button";
import { addPost } from "../../store/actions/blog/addPost";
import {
  addCategory,
  getAllCategories,
} from "../../store/actions/blog/addCategory";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { formHandler } from "../../utils/formhandler";
import TextInput from "../../components/common/TextInput";

function AddCategory() {
  const dispatch = useDispatch();

  const form = useFormik({
    initialValues: {
      category: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      const data = {
        ...values,
      };

      dispatch(addCategory({ data, setSubmitting }));
    },
  });

  return (
    <div className="font-mons">
      <SEO
        title={"Blog"}
        description={""}
        name={"Imperial Finance"}
        type={"article"}
      />
      <Header />

      <div className="bg-[#FFFFFF] pt-[72px] font-mons flex min-h-screen">
        <SideBar />

        <div className="flex-1 max-w-full pt-4 px-4 md:px-10 bg-[#F1F1F1] relative w-full">
          <div className="flex flex-wrap mt-6  w-full">
            <div className="w-full lg:w-3/4 lg:mr-6">
              <div className="flex justify-between mb-2">
                <h1 className="font-semibold text-[20px] leading-[24.38px] tracking-[-0.01em] text-[#000000]">
                  Add Category
                </h1>
              </div>
              <div className="mt-6">
                <TextInput
                  {...formHandler("category", form)}
                  type="text"
                  placeholder="Add a category"
                  mainClasses={"w-full md:w-[463px]"}
                />

                <Button
                  bg={"#192851"}
                  width={"239px"}
                  title={"Create Category"}
                  onClick={form.handleSubmit}
                  mainClasses={"mt-10 w-[183px]"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCategory;
