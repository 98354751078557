import { createAsyncThunk } from "@reduxjs/toolkit";
import { APPROVE_TRANSACTION, GET_ALL_TRANSACTION } from "../../../config/apis";
import { Client } from "../../../config/client";
import { proccessErrorMessage } from "../../../utils/proccessErrorMessage";
import { toast } from "react-toastify";

export const getAllTransactions = createAsyncThunk(
  "transaction/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: GET_ALL_TRANSACTION,
      });

      console.log("res", response)

      if (response?.data?.success) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      toast.error(error.response?.data?.message);
      proccessErrorMessage(error);
      return rejectWithValue(error.response?.data);
    }
  }
);

export const approveTransaction = createAsyncThunk(
  "transaction/approve",
  async ({ reference }, { dispatch, rejectWithValue }) => {
    console.log("reference", reference)
    try {
      const response = await Client({
        method: "POST",
        path: APPROVE_TRANSACTION,
        data: {
          reference,
          status: "success"
        }
      });

      if (response?.data?.success) {
        toast.success(response.data.message);
        dispatch(getAllTransactions())
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred while approving the transaction.");
      proccessErrorMessage(error);
      return rejectWithValue(error.response?.data);
    }
  }
);


