import Axios from "axios";
import { proccessErrorMessage } from "../utils/proccessErrorMessage";

import { baseURL } from "./apis";
import { Cookies } from "react-cookie";

export const Client = async (params) => {
  const cookies = new Cookies();


  const token = cookies.get("accessToken");


  const {
    path,
    fullPath,
    method,
    data,
    contentType = "application/json",
  } = params;
  const headers = {
    ...(token &&
      {
         Authorization: `Bearer ${token}`,
      }),

    Accept: "application/json",
    "Content-Type": `${contentType}`,
  };

  let url = fullPath || `${baseURL}${path}`;
  const requestBody = {
    method,
    url,
    headers,
    data: contentType === "multipart/form-data" ? data : JSON.stringify(data),
  };

  try {
    const response = await Axios(requestBody);
    const result = response;
    return result;
  } catch (error) {
    try {
      if (
        error?.response?.data?.message === "Unauthenticated." ||
        error?.message === "Unauthenticated." ||
        error === "Unauthenticated."
      ) {
        localStorage.clear();
        cookies.remove("accessToken");
      }
      if (params?.processError === false) {
        throw error;
      } else {
        proccessErrorMessage(error);
        throw error;
      }
    } catch (error) {
      throw error;
    }
  }
};
