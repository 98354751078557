import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import Transactions from "./pages/Transactions";
import Customers from "./pages/Customers";
import Exchange from "./pages/Exchange";
import "./index.css"
import AddPost from "./pages/blog/AddPost";
import AllPost from "./pages/blog/AllPost";
import Settings from "./pages/Settings";
import AddCategory from "./pages/blog/AddCategory";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
 
  {
    path: "sign-in",
    element: <Login />,
  },
 

  {
    path: "*",
    element: <Navigate to={"/"} />,
  },
]);
const protectedRouter = createBrowserRouter([

  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/transactions",
    element: <Transactions />,
  },
  {
    path: "/customers",
    element: <Customers />,
  },
  {
    path: "/exchange",
    element: <Exchange />,
  },
  {
    path: "/add-new-post",
    element: <AddPost />,
  },
      {
    path: "/all-posts",
    element: <AllPost />,
  },
      {
    path: "/create-category",
    element: <AddCategory />,
  },
      {
    path: "/settings",
    element: <Settings />,
  },

  {
    path: "*",
    element: <Navigate to={"/dashboard"} />,
  },
]);

function App() {
  const { user } = useSelector((state) => state.user);

  return (
    <>
      <HelmetProvider>
        <RouterProvider router={user?.email ? protectedRouter : router} />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          closeOnClick
          ltr={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          hideProgressBar={true}
        />
      </HelmetProvider>
    </>
  );
}

export default App;
