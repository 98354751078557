import react, { useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import Button from "../common/Button";
import { useDispatch } from "react-redux";
import { deletePostById } from "../../store/actions/blog/addPost";

const columns: GridColDef[] = [
  { field: "headLine", headerName: "Title", width: 337 },
  { field: "category", headerName: "Category", width: 230 },
  { field: "status", headerName: "Status", width: 230 },
  {
    field: "date",
    headerName: "Date",
    width: 230,
    type: "date",
    valueFormatter: (params) => {
      const date = params;
      return format(new Date(date), "MMM dd, yyyy");
    },
  },
];

export default function DataTable({ posts }) {
  const dispatch = useDispatch();
  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleRowClick = (params) => {
    console.log("params", params);
    setSelectedRowId(params.id);
  };

  const handleDelete = () => {
    if (selectedRowId) {
      dispatch(deletePostById(selectedRowId));
      setSelectedRowId(null);
    }
  };

  return (
    <>
      <div
        style={{ height: "100%", width: "100%", backgroundColor: "#ffffff" }}
      >
        <DataGrid
          rows={posts}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          onRowClick={handleRowClick}
        />
      </div>
      <div>
        <Button
          title={"Delete"}
          bg={"#192851"}
          width="107px"
          height={"45px"}
          mainClasses={
            "flex flex-col md:flex-row items-center w-full md:w-auto text-[#ffffff]"
          }
          textStyles={"group-hover:text-[#192851] "}
          onClick={handleDelete}
        />
      </div>
    </>
  );
}
