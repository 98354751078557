import React, { useMemo, useState } from "react";
import { HiOutlineChevronDown } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { BsFillCaretDownFill } from "react-icons/bs";
import { TbLogout2 } from "react-icons/tb";
import { IoHelpCircleOutline } from "react-icons/io5";
import { CiSettings } from "react-icons/ci";
import { BsFillPersonFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../store/reducer/userSlice";
import { FaChevronDown } from "react-icons/fa6";
import { RiUserSharedLine } from "react-icons/ri";
import { RxDashboard } from "react-icons/rx";
import { MdOutlineMenu } from "react-icons/md";
import { toggleSideMenu } from "../../store/reducer/uiSlice";
import { Cookies } from "react-cookie";
const Menu = ({ menuOpen, setMenuOpen }) => {
  const pathName = useLocation().pathname;
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const handleClickLink = (param) => {
    navigate(param);
    setMenuOpen(false);
  };
  const links = useMemo(() => {
    return user?.email
      ? [
          { id: 1, textLink: "Dashboard", link: "/dashboard" },
          { id: 3, textLink: "Financial News", link: "/financial-news" },

          { id: 4, textLink: "About", link: "/about-us" },
          { id: 5, textLink: "Services", link: "#" },
        ]
      : [
          { id: 1, textLink: "Home", link: "/" },
          { id: 3, textLink: "Sign in", link: "/sign-in" },

          { id: 4, textLink: "About", link: "/about-us" },
          { id: 5, textLink: "Services", link: "#" },
        ];
  }, [user]);

  return (
    <div className="">
      <div className="flex flex-col items-center menu-height lg:hidden ">
        <div className="pt-20">
          <ul className="">
            {links.map(({ id, textLink, link, hasOthers }) => (
              <li
                className={`text-[#192851] font-normal flex gap-1 justify-center items-center text-base py-6 text-center ${
                  pathName == link && "active2"
                }`}
                key={id}
                onClick={() => handleClickLink(link)}
              >
                <p href={link}>{textLink}</p>
                {hasOthers && (
                  <HiOutlineChevronDown
                    className="group-hover:text-gray-800 mt-[1px]"
                    size={20}
                    color="#475467"
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const Header = () => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  const { user } = useSelector((state) => state.user);

  console.log("user", user);

  const cookies = new Cookies();
  const handleLogOut = () => {
    cookies.remove("accessToken");
    localStorage.clear();
    dispatch(logOut());
  };

  return (
    <>
      <nav id="header" className="fixed    w-full z-30 top-0 bg-[#192851]">
        <div className="w-full pl-10 pr-8 mx-auto flex   justify-between mt-0 ">
          <div className="flex py-[16px] gap-10 items-center w-full justify-between">
            {user?.email ? (
              <div className="flex sm:gap-4 laptop:hidden h-[2rem] ">
                <MdOutlineMenu
                  size={"30px"}
                  color="#FFF"
                  onClick={() => {
                    dispatch(toggleSideMenu());
                  }}
                  className="cursor-pointer"
                />
              </div>
            ) : (
              <></>
            )}
            <div className="pl-4 cursor-pointer flex gap-4 items-center">
              <a href="/">
                <img
                  src={require("../../assets/Logo/logo-transparent.png")}
                  className="w-[32px] md:w-[40px] h-[34px] md:h-[42px]"
                />
              </a>
              <a href="/">
                <h4 className="font-[500] hidden sm:block text-[18px] text-[#FFFFFF]">
                  Imperial Finance
                </h4>
              </a>
            </div>
          </div>

          <div className="block py-[16px] lg:hidden pr-4">
            {user?.email ? (
              <></>
            ) : (
              <button
                id="nav-toggle"
                className="flex items-center p-1 text-[#FFF] focus:outline-none focus:shadow-outlinet"
              >
                {!menuOpen ? (
                  <MdOutlineMenu
                    size={"30px"}
                    onClick={() => setMenuOpen(!menuOpen)}
                    className="cursor-pointer"
                  />
                ) : (
                  <span onClick={() => setMenuOpen(!menuOpen)}>
                    <AiOutlineClose size={24} color="white" />
                  </span>
                )}
              </button>
            )}
          </div>
          <div className="hidden gap-2 lg:flex ">
            {user?.email ? (
              <div tabIndex={1} className="flex items-center group relative">
                <BsFillPersonFill
                  onClick={() => {}}
                  color="#FFF"
                  size={35}
                  className=" cursor-pointer"
                />

                <BsFillCaretDownFill
                  onClick={() => {}}
                  color="#FFF"
                  size={16}
                  className="cursor-pointer ml-[-2px]"
                />

                <div className="absolute hidden group-focus:block top-[56px] right-3 w-[250px]  py-4  bg-[#F9F9F9] rounded-[6px] overflow-hidden">
                  <h4 className="text-[14px] text-center font-[700]">
                    Hi, {user?.name}
                  </h4>
                  <h4 className="text-[11px] text-center font-[400]">
                    {user?.email}
                  </h4>
                  <h4 className="text-[11px] text-center font-[400]">
                    Referal code: GD2138A
                  </h4>
                  <div className="border-b-[0.7px] border-b-[#E3E1E1] mt-3 mb-2"></div>
                  <div
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                    className="w-full flex h-[31px] items-center py-2 px-5 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1]"
                  >
                    <RxDashboard size="15px" />
                    <h4 className="text-[12px] font-[500]">Dashboard</h4>
                  </div>
                  <div
                    // onClick={handleLogOut}
                    className="w-full flex h-[31px] items-center py-2 px-5 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1]"
                  >
                    <div className="w-[16px] ">
                      <img
                        src={require(".././../assets/Icons/refer.png")}
                        className="w-[15px] h-[15px]"
                        alt="kyc"
                      />
                    </div>
                    <h4 className="text-[12px] font-[500]">Refer and Earn</h4>
                  </div>
                  <div
                    // onClick={handleLogOut}
                    className="w-full flex h-[31px] items-center py-2 px-5 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1]"
                  >
                    <IoHelpCircleOutline size="17px" />
                    <h4 className="text-[12px] font-[500]">Help</h4>
                  </div>
                  <div
                    onClick={() => {
                      navigate("/settings");
                    }}
                    className="w-full flex h-[31px] items-center py-2 px-5 gap-2 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1]"
                  >
                    <div className="w-[20px] ">
                      <CiSettings size="20px" />
                    </div>
                    <h4
                      className="text-[12px] font-[500]"
                      onClick={() => {
                        navigate("/settings");
                      }}
                    >
                      Settings
                    </h4>
                  </div>
                  <div
                    onClick={handleLogOut}
                    className="w-full flex h-[31px] items-center py-2 px-5 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1]"
                  >
                    <div className="w-[16px] ">
                      <img
                        src={require(".././../assets/Icons/logout.png")}
                        className="w-[15px] h-[15px]"
                        alt="kyc"
                      />
                    </div>
                    <h4 className="text-[12px] font-[500]">Log out</h4>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </nav>
      {menuOpen && !user?.email && (
        <div
          className={`fixed mt-[60px] w-full h-full z-50 bg-white ${
            menuOpen ? "menu-wrapper " : " menu-hidden"
          }`}
        >
          <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        </div>
      )}
    </>
  );
};

export default Header;
