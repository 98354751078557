import React, { useState } from "react";
import { RxDashboard } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toggleSideMenu } from "../../store/reducer/uiSlice";
import { MdOutlineClose } from "react-icons/md";
import { logOut } from "../../store/reducer/userSlice";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { Cookies } from "react-cookie";

const SideBar = () => {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const dispatch = useDispatch();
  const cookies = new Cookies();

  const { openSideMenu } = useSelector((state) => state.ui);

  const handleLogOut = () => {
    localStorage.clear();
    cookies.remove("accessToken");
    dispatch(logOut());
  };

  const [showBlogDropdown, setShowBlogDropdown] = useState(false);

  return (
    <>
      {openSideMenu ? (
        <div
          onClick={() => {
            dispatch(toggleSideMenu());
          }}
          className="bg-[rgba(255,255,255,0.3)] laptop:hidden z-40 fixed top-0 left-0 w-full h-screen"
        ></div>
      ) : (
        <></>
      )}
      <div
        className={` laptop:block  bg-[#FFF] laptop:relative min-h-screen  left-0 z-50 laptop:z-auto w-[313px] pt-2 h-[100vh] laptop:h-auto min-h-[80vh] flex flex-col lg:min-h-screen md:min-h-screen min-h-screen  fixed top-[0px] ${
          openSideMenu ? "" : "hidden"
        }`}
      >
        <div className="flex justify-between laptop:hidden items-center py-2 px-8">
          <img
            src={require("../../assets/Logo/logo.png")}
            className="w-[50px] h-[45px] object-contain"
            alt="logo"
          />
          <div className="  ">
            <MdOutlineClose
              size={"26px"}
              onClick={() => {
                dispatch(toggleSideMenu());
              }}
              className="cursor-pointer"
            />
          </div>
        </div>
        <div className="w-full flex-[3] flex flex-col gap-3 py-5 px-3">
          <div
            onClick={() => {
              navigate("/dashboard");
            }}
            className={`w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1] ${
              pathname === "/dashboard" ? "bg-[#E2E1E1]" : ""
            }`}
          >
            <div className="w-[20px] ">
              <RxDashboard size="20px" />
            </div>
            <h4 className="text-[16px] font-[400] text-[#000000]">Dashboard</h4>
          </div>
          <div
            onClick={() => {
              navigate("/transactions");
            }}
            className={`w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1] ${
              pathname === "/transactions" ? "bg-[#E2E1E1]" : ""
            }`}
          >
            <div className="w-[20px] ">
              <img
                src={require(".././../assets/Icons/credit-card.png")}
                className="w-[18px] h-[18px] object-contain"
                alt="wallet"
              />
            </div>
            <h4 className="text-[16px] font-[400] text-[#000000]">
              Transactions
            </h4>
          </div>
          <div className="flex flex-col gap-1">
            <div
              onClick={() => {
                navigate("/customers");
              }}
              className={`w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1] ${
                pathname === "/customers" ? "bg-[#E2E1E1]" : ""
              }`}
            >
              <div className="w-[20px] ">
                <img
                  src={require(".././../assets/Icons/beneficiaries.png")}
                  className="w-[18px] h-[18px] object-contain"
                  alt="beneficiaries"
                />
              </div>
              <div className="flex gap-[4px] items-center">
                <h4 className="text-[16px] font-[400] text-[#000000]">
                  Customers
                </h4>
              </div>
            </div>
          </div>
          <div
            onClick={() => navigate("/exchange")}
            className={`w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1] ${
              pathname === "/exchange" ? "bg-[#E2E1E1]" : ""
            }`}
          >
            <div className="w-[20px] ">
              <img
                src={require(".././../assets/Icons/exchange.png")}
                className="w-[18px] h-[18px] object-contain"
                alt="track"
              />
            </div>
            <h4 className="text-[16px] font-[400] text-[#000000]">
              Exchange Rates
            </h4>
          </div>
          <div className="flex flex-col gap-1">
            <div
              onClick={() => setShowBlogDropdown(!showBlogDropdown)}
              className={`w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1] ${
                pathname === "/recurring-payments" ? "bg-[#E2E1E1]" : ""
              }`}
            >
              <div className="w-[20px]">
                <img
                  src={require(".././../assets/Icons/list.png")}
                  className="w-[18px] h-[18px] object-contain"
                  alt="recurring"
                />
              </div>
              <h4 className="text-[16px] font-[400] text-[#000000]">
                Blog Post
              </h4>
              <div className="ml-auto">
                {showBlogDropdown ? (
                  <FaChevronUp size="10px" />
                ) : (
                  <FaChevronDown size="10px" />
                )}
              </div>
            </div>
            {showBlogDropdown && (
              <div className="flex flex-col pl-10">
                <div
                  onClick={() => navigate("/add-new-post")}
                  className={`w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px] hover:bg-[#E2E1E1] ${
                    pathname === "/add-new-post" ? "bg-[#E2E1E1]" : ""
                  }`}
                >
                  <h4 className="text-[16px] font-[400] text-[#000000]">
                    Add New Post
                  </h4>
                </div>
                <div
                  onClick={() => navigate("/all-posts")}
                  className={`w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px] hover:bg-[#E2E1E1] ${
                    pathname === "/all-posts" ? "bg-[#E2E1E1]" : ""
                  }`}
                >
                  <h4 className="text-[16px] font-[400] text-[#000000]">
                    All Posts
                  </h4>
                </div>
              </div>
            )}
          </div>
          <div
            onClick={() => navigate("/settings")}
            className={`w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1] ${
              pathname === "/settings" ? "bg-[#E2E1E1]" : ""
            }`}
          >
            <div className="w-[20px] ">
              <img
                src={require(".././../assets/Icons/settings.png")}
                className="w-[18px] h-[18px] object-contain"
                alt="transaction-history"
              />
            </div>
            <h4 className="text-[16px] font-[400] text-[#000000]">Settings</h4>
          </div>
          <div
            onClick={handleLogOut}
            className="w-full flex h-[46px] items-center py-2 pl-10 gap-3 cursor-pointer rounded-[8px]  hover:bg-[#E2E1E1]"
          >
            <div className="w-[20px] ">
              <img
                src={require(".././../assets/Icons/logout.png")}
                className="w-[18px] h-[18px] object-contain"
                alt="logout"
              />
            </div>
            <h4 className="text-[16px] font-[400] text-[#000000]">Log out</h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
