import { createSlice } from "@reduxjs/toolkit";

import { addCategory, getAllCategories, } from "../actions/blog/addCategory";
import { addPost, getAllDrafted, getAllPost, getAllPublished  } from "../actions/blog/addPost";

export const blogSlice = createSlice({
  name: "blog",
  initialState: {
    error: null,
    pending: false,
    success: false,
    categories: [],
    posts: [],
    draft: [],
    published: []
  },

  reducers: {
 
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCategory.pending, (state) => {
        state.pending = true;
        state.success = false;
        state.error = null;
      })
      .addCase(addCategory.fulfilled, (state) => {
        state.pending = false;
        state.success = true;
        state.error = null;
      })
      .addCase(addCategory.rejected, (state, action) => {
        state.pending = false;
        state.success = false;
        state.error = action.error.message;
      })
      .addCase(addPost.pending, (state) => {
        state.pending = true;
        state.success = false;
        state.error = null;
      })
      .addCase(addPost.fulfilled, (state) => {
        state.pending = false;
        state.success = true;
        state.error = null;
      })
      .addCase(addPost.rejected, (state, action) => {
        state.pending = false;
        state.success = false;
        state.error = action.error.message;
      })
       .addCase(getAllCategories.pending, (state) => {
        state.pending = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.pending = false;
        state.success = true;
        state.error = null;
        state.categories = action.payload; 
      })
      .addCase(getAllCategories.rejected, (state, action) => {
        state.pending = false;
        state.success = false;
        state.error = action.error.message;
      })
       .addCase(getAllPost.pending, (state) => {
        state.pending = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getAllPost.fulfilled, (state, action) => {
        state.pending = false;
        state.success = true;
        state.error = null;
        state.posts = action.payload; 
      })
      .addCase(getAllPost.rejected, (state, action) => {
        state.pending = false;
        state.success = false;
        state.error = action.error.message;
      })
       .addCase(getAllPublished.pending, (state) => {
        state.pending = true;
        state.success = false;
        state.error = null;
     
      })
      .addCase(getAllPublished.fulfilled, (state, action) => {
        state.pending = false;
        state.success = true;
        state.error = null;
        state.published = action.payload
      })
      .addCase(getAllPublished.rejected, (state, action) => {
        state.pending = false;
        state.success = false;
        state.error = action.error.message;
      })
       .addCase(getAllDrafted.pending, (state) => {
        state.pending = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getAllDrafted.fulfilled, (state, action) => {
        state.pending = false;
        state.success = true;
        state.error = null;
        state.draft = action.payload
      })
      .addCase(getAllDrafted.rejected, (state, action) => {
        state.pending = false;
        state.success = false;
        state.error = action.error.message;
      })
  },
});

export const {  } = blogSlice.actions;
export default blogSlice.reducer;
