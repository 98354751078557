import React, { useEffect, useState } from "react";
import TextInput from "../common/TextInput";
import Button from "../common/Button";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { loginSchema } from "../../utils/validationSchemas";
import { useFormik } from "formik";
import { formHandler } from "../../utils/formhandler";
import { login } from "../../store/actions/onboarding/login";
import { useCookies } from "react-cookie";
import { loginWithGoogle } from "../../store/actions/onboarding/loginWithGoogle";
import FacebookLogin from "react-facebook-login";
// import { firebaseAuth } from "../../config/firebase";
// import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const now = new Date();
const next15Mins = new Date(now.setMinutes(now.getMinutes() + 15));
const next2Days = new Date(now.setDate(now.getDate() + 2));
const next30Days = new Date(now.setDate(now.getDate() + 30));

const LoginMain = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [remember, setRemember] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["auth"]);

  useEffect(() => {
    if (cookies.email) {
      setRemember(true);
    }
  }, [cookies.email]);

  const form = useFormik({
    initialValues: {
      email: cookies?.email || "",
      password: cookies?.password || "",
    },
    validationSchema: loginSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (remember) {
        setCookie("email", values.email, {
          // path: "/",
          expires: next30Days,
          secure: true,
        });
        setCookie("password", values.password, {
          // path: "/",
          expires: next30Days,
          secure: true,
        });
      } else {
        if (cookies.email) removeCookie("email");
        if (cookies.password) removeCookie("password");
      }
      const data = {
        ...values,
      };

      dispatch(login({ navigate, data, setSubmitting }));
    },
  });

  useEffect(() => {
    form.setFieldValue("email", cookies?.email);
    form.setFieldValue("password", cookies?.password);
  }, [cookies]);

  return (
    <>
      <div className="w-[90%] md:w-[437px] justify-between rounded-[28px] bg-[rgba(255,255,255,0.9)] flex-col flex py-12 3xl:py-8 px-6 md:h-[582px] max-h-[80%] lg:max-h-[80%]   items-center">
        <div className="flex-col mt-0 3xl:mt-6 mb-5 flex items-center w-full">
          <h3 className="text-[#000] mt-4 mb-1 font-[700] text-[24px] text-center">
            Login
          </h3>
          <h3 className="text-[#707070] font-[400] text-[16px] text-center">
            Please provide your details to continue
          </h3>

          <TextInput
            title={"Email address"}
            {...formHandler("email", form)}
            type={"email"}
            mainClasses={"mb-[8px] mt-6 w-full md:w-[367px]"}
          />
          <TextInput
            title={"Password"}
            {...formHandler("password", form)}
            type={"password"}
            mainClasses={"w-full md:w-[367px]"}
          />

          <div className="mb-5 w-full md:w-[363px] mt-[10px]  flex justify-between items-center ">
            <div
              onClick={() => {
                setRemember((prev) => !prev);
              }}
              className="flex items-center gap-1 cursor-pointer"
            >
              <div
                className={`${
                  remember ? "" : ""
                } flex justify-center items-center rounded-[8px] border-[0.75px]  h-[16px] w-[16px] border-[#1873B9] transition-all ease-in-out duration-300`}
              >
                <div
                  className={`${
                    remember ? "bg-[#1873B9]" : "bg-transparent"
                  }  w-[8px] h-[8px] mr-[-0.3px] rounded-[4px] transition-all ease-in-out duration-300`}
                ></div>
              </div>
              <h4 className="text-[#333] font-[500] text-[13px] ">
                Remember me
              </h4>
            </div>
            <h4
              onClick={() => {}}
              className="text-[#333] font-[500] text-[13px] cursor-pointer"
            >
              Forgot Password?
            </h4>
          </div>
          <Button
            title={"Login"}
            bg={"#192851"}
            onClick={form.handleSubmit}
            loading={form.isSubmitting}
            disabled={
              (!form.dirty && !cookies?.email) ||
              !form.isValid ||
              form.isSubmitting
            }
            mainClasses={
              "mt-10 md:mt-10 hover:text-[red] hover:border-[#192851] "
            }
            textStyles={"group-hover:text-[#192851]"}
          />
        </div>
      </div>
    </>
  );
};

export default LoginMain;
