import { createSlice } from "@reduxjs/toolkit";

import { register } from "../actions/onboarding/register";
import { login } from "../actions/onboarding/login";
import { loginWithGoogle } from "../actions/onboarding/loginWithGoogle";
import { loginWithFacebook } from "../actions/onboarding/loginWithFacebook";
import { changePassword } from "../actions/onboarding/changePassword";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,

    error: null,
    pending: false,

    success: false,
  },

  reducers: {
    logOut: (state, action) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      state.error = null;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.error = null;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.error = null;
      state.user = action.payload;
    });
   
       builder.addCase(changePassword.pending, (state) => {
      state.pending = true;
      state.success = false;
      state.error = null;
    });
    builder.addCase(changePassword.fulfilled, (state) => {
      state.pending = false;
      state.success = true;
      state.error = null;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.pending = false;
      state.success = false;
      state.error = action.error.message;
    });
  },
});

export const {
  completeOnboarding,
  setCleanerslocation,
  removeClean,
  updateClean,
  setAmendRequest,
  logOut,
  resetCleanCanceled,
  clearContactMe,
  showContactMeAction,
  resetCleanRequest,
  clearCleanRequest,
  clearPendingRatings,
  setRequestOppParty,
} = userSlice.actions;
export default userSlice.reducer;
