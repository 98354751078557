import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_POST,
  DELETE_POST,
  GET_ALL_DRAFTED,
  GET_ALL_POST,
  GET_ALL_PUBLISHED,
} from "../../../config/apis";
import { Client } from "../../../config/client";
import { processSuccessMessage } from "../../../utils/processSuccessMessage";
import { proccessErrorMessage } from "../../../utils/proccessErrorMessage";
import { toast } from "react-toastify";

export const addPost = createAsyncThunk("post/add", async (payload) => {
  const { formData, setSubmitting } = payload;

  try {
    const newData = await Client({
      method: "POST",
      path: ADD_POST,
      data: formData,
      contentType: "multipart/form-data",
    });

    setSubmitting(false);
    return newData.data;
  } catch (error) {
    toast.error(error.response?.data?.message || "Post addition failed");
    setSubmitting(false);
    proccessErrorMessage(error);
    throw error;
  }
});

export const getAllPost = createAsyncThunk(
  "posts/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: GET_ALL_POST,
      });

      return response.data;
    } catch (error) {
      toast.error(error.response?.data?.message);
      proccessErrorMessage(error);
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deletePostById = createAsyncThunk(
  "posts/deleteById",
  async (postId, { rejectWithValue, dispatch }) => {
    try {
      const response = await Client({
        method: "DELETE",
        path: `${DELETE_POST}/${postId}`,
      });

      toast.success("Post deleted successfully");
      dispatch(getAllPost());
      return response.data;
    } catch (error) {
      // toast.error(error.response?.data?.message);
      //processErrorMessage(error);
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAllPublished = createAsyncThunk(
  "published/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: GET_ALL_PUBLISHED,
      });

      return response.data;
    } catch (error) {
      toast.error(error.response?.data?.message);
      proccessErrorMessage(error);
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAllDrafted = createAsyncThunk(
  "drafts/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: GET_ALL_DRAFTED,
      });

      console.log("drafted", response);

      return response.data;
    } catch (error) {
      toast.error(error.response?.data?.message);
      proccessErrorMessage(error);
      return rejectWithValue(error.response?.data);
    }
  }
);
