import { createAsyncThunk } from "@reduxjs/toolkit";
import { Client } from "../../../config/client";
import { proccessErrorMessage } from "../../../utils/proccessErrorMessage";
import { toast } from "react-toastify";
import { GET_ALL_DASHBOARD } from "../../../config/apis";

export const getDashboard = createAsyncThunk(
  "dashboardMetrics/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: GET_ALL_DASHBOARD,
      });

      console.log("res", response)

        return response.data;
 
    } catch (error) {
      toast.error(error.response?.data?.message);
      proccessErrorMessage(error);
      return rejectWithValue(error.response?.data);
    }
  }
);