import { createAsyncThunk } from "@reduxjs/toolkit";
import { RATE } from "../../../config/apis";
import { Client } from "../../../config/client";
import { processSuccessMessage } from "../../../utils/processSuccessMessage";
import { proccessErrorMessage } from "../../../utils/proccessErrorMessage";
import { toast } from "react-toastify";

export const rate = createAsyncThunk("rate", async (payload, { rejectWithValue }) => {
 
    const { data, setSubmitting } = payload;

  try {
    const response = await Client({
      method: "POST",
      path: RATE,
      data: data,
    });

    console.log("response", response)


    processSuccessMessage(response.data?.message);
    toast.success("Rate updated successfully");
    setSubmitting(false);
    return response.data.user; 
  } catch (error) {
    toast.error("Failed to update rate");
    return rejectWithValue(error.response.data); 
  }
});
