import { createSlice } from "@reduxjs/toolkit";
import { approveTransaction, getAllTransactions } from "../actions/transaction/getTransaction";

export const transactionSlice = createSlice({
  name: "transactions",
  initialState: {
    success: false,
    error: null,
    pending: false,
    transactions: [],
    approvePending: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllTransactions.pending, (state) => {
        state.error = null;
        state.pending = true;
      })
      .addCase(getAllTransactions.fulfilled, (state, action) => {
        state.error = null;
        state.pending = false;
        state.success = true; 
        state.transactions = action.payload
      })
      .addCase(getAllTransactions.rejected, (state, action) => {
        state.error = action.payload ? action.payload.message : "Failed to update rate";
        state.pending = false;
      })
      .addCase(approveTransaction.pending, (state) => {
        state.error = null;
        state.approvePending = true;
      })
      .addCase(approveTransaction.fulfilled, (state, action) => {
        state.error = null;
        state.approvePending = false;
        state.success = true; 
      })
      .addCase(approveTransaction.rejected, (state, action) => {
        state.error = action.payload ? action.payload.message : "Failed to approve";
        state.approvePending = false;
      });
  },
});

export const {} = transactionSlice.actions;
export default transactionSlice.reducer;