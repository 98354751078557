import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_ALL_CUSTOMER,  } from "../../../config/apis";
import { Client } from "../../../config/client";
import { proccessErrorMessage } from "../../../utils/proccessErrorMessage";
import { toast } from "react-toastify";

export const getAllCustomer = createAsyncThunk(
  "customer/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: GET_ALL_CUSTOMER,
      });

      console.log("res", response)

      if (response?.data?.success) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      toast.error(error.response?.data?.message);
      proccessErrorMessage(error);
      return rejectWithValue(error.response?.data);
    }
  }
);