import React, { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import SEO from "../components/utils/Seo";
import SideBar from "../components/layout/SideBar";
import IncomeAndExpenditure from "../components/charts/IncomeAndExpenditure";
import CustomPiechart from "../components/charts/CustomPiechart";
import Button from "../components/common/Button";
import { FaChevronDown } from "react-icons/fa6";
import ButtonDropDown from "../components/common/ButtonDropDown";
import { CgChevronRight } from "react-icons/cg";
import { TbEyeOff } from "react-icons/tb";
import { TbEye } from "react-icons/tb";
import CustomLabelChart from "../components/charts/CustomLabelChart";
import { getDashboard } from "../store/actions/dashboard/dashboard";
import { useDispatch, useSelector } from "react-redux";
import SelectInput from "@mui/material/Select/SelectInput";
import { useFormik } from "formik";
import { formHandler } from "../utils/formhandler";
import { MenuItem, Select } from "@mui/material";
import { formatCurrency } from "../components/utils/currencyFormatter";

const Dashboard = () => {
  const dispatch = useDispatch();
  const dashboardData = useSelector((state) => state.dashboard?.dashboard);

  console.log("dashboard", dashboardData);

  const transactions =
    useSelector((state) => state?.transactions?.transactions?.data) || [];
  const [currentWallet, setCurrentWallet] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState("Naira");
  const [selectedDateRange, setSelectedDateRange] = useState(7);

  const currencyMap = {
    Naira: "NGN",
    Dollar: "USD",
    Pound: "GBP",
  };

  const currentCurrencyCode = currencyMap[selectedCurrency];
  const totalMoneySent =
    dashboardData?.totalMoneySent[currentCurrencyCode] || 0;
  const totalMoneySentToRecipient =
    dashboardData?.totalMoneySentToRecipient[currentCurrencyCode] || 0;

  const [show, setShow] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    dispatch(getDashboard());
  }, [dispatch]);

  const wallets = [
    {
      name: "Naira Wallet",
      amount: formatCurrency(
        dashboardData?.walletBalance?.totalNairaBalance,
        "NGN"
      ),
    },
    {
      name: "Dollar Wallet",
      amount: formatCurrency(
        dashboardData?.walletBalance?.totalDollarBalance,
        "USD"
      ),
    },
    {
      name: "Pound Wallet",
      amount: formatCurrency(
        dashboardData?.walletBalance?.totalPoundBalance,
        "GBP"
      ),
    },
  ];

  useEffect(() => {
    switch (selectedCurrency) {
      case "Naira":
        setCurrentWallet(0);
        break;
      case "Dollar":
        setCurrentWallet(1);
        break;
      case "Pound":
        setCurrentWallet(2);
        break;
      default:
        setCurrentWallet(0);
    }
  }, [selectedCurrency]);

  return (
    <div className="font-mons">
      <SEO
        title={"Dashboard"}
        description={""}
        name={"Imperial Finance"}
        type={"article"}
      />
      <Header />
      <div className="bg-[#FFFFFF] pt-[72px] font-mons flex">
        <SideBar />
        <div className="flex-1 max-w-[100vw] overflow-x-auto pt-12 px-10 bg-[#F1F1F1]">
          <h1 className="font-semibold text-[20px] leading-[24.38px] tracking-[-0.01em] text-[#000000] mb-2">
            Overview
          </h1>
          <div className="flex w-full sm:min-w-[640px] md:min-w-[768px] lg:min-w-[1024px] xl:min-w-[1280px] gap-5 mt-2 flex-wrap flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row">
            <div className="bg-[#FFF] border-[#D1D1D1] w-full md:w-[243px] h-[164px] rounded-[15px] border-[0.8px] border-gray-400 px-5 py-5">
              <h4 className="font-medium text-[16px] leading-[19.5px] tracking-[-0.01em] text-[#000000]">
                Total money
                <br />
                sent out
              </h4>
              <p className="font-semibold mt-4 text-[20px] leading-[30.48px] tracking-[-0.01em] text-[#000000]">
                {formatCurrency(totalMoneySent, currentCurrencyCode)}
              </p>
              <div className="flex items-center py-2">
                <div className="flex items-center bg-[#A9FFA7] p-1 rounded-[10px] mr-3">
                  <img
                    src={require(".././assets/Icons/trend.png")}
                    className="w-[18px] h-[18px] object-contain"
                    alt="wallet"
                  />
                  <span className="ml-2 text-[#037400]">20%</span>
                </div>
                <p className="font-normal text-[12px] leading-[14.63px] tracking-[-0.01em] text-[#7C7C7C]">
                  Last 7 Days
                </p>
              </div>
            </div>
            <div className="bg-[#FFF] border-[#D1D1D1] w-full md:w-[243px] h-[164px] rounded-[15px] border-[0.8px] border-gray-400 px-5 py-5">
              <h4 className="font-medium text-[16px] leading-[19.5px] tracking-[-0.01em] text-[#000000]">
                Total Transactions
                <br />
                volume
              </h4>
              <p className="font-semibold mt-4 text-[25px] leading-[30.48px] tracking-[-0.01em] text-[#000000]">
                {dashboardData?.totalTransactionVolume}
              </p>
            </div>
            <div className="bg-[#FFF] border-[#D1D1D1] w-full md:w-[243px] h-[164px] rounded-[15px] border-[0.8px] border-gray-400 px-5 py-5 gap-[10px]">
              <h4 className="font-medium text-[16px] leading-[19.5px] tracking-[-0.01em] text-[#000000]">
                Total visitors
                <br />
                received
              </h4>
              <p className="font-semibold mt-4 text-[25px] leading-[30.48px] tracking-[-0.01em] text-[#000000]">
                {dashboardData?.totalVisitors}
              </p>
              <div className="flex items-center py-2">
                <div className="flex items-center bg-[#F4C1C1] p-1 rounded-[10px] mr-3">
                  <img
                    src={require(".././assets/Icons/tred.png")}
                    className="w-[18px] h-[18px] object-contain"
                    alt="wallet"
                  />
                  <span className="ml-2 text-[#FF0000]">20%</span>
                </div>
                <p className="font-normal text-[12px] leading-[14.63px] tracking-[-0.01em] text-[#7C7C7C]">
                  Last 7 Days
                </p>
              </div>
            </div>
            <div className="bg-[#FFF] border-[#D1D1D1] w-full md:w-[243px] h-[164px] rounded-[15px] border-[0.8px] border-gray-400 px-5 py-5 gap-[10px]">
              <h4 className="font-medium text-[16px] leading-[19.5px] tracking-[-0.01em] text-[#000000]">
                Total money paid to
                <br />
                recipient
              </h4>
              <p className="font-semibold mt-4 text-[20px] leading-[30.48px] tracking-[-0.01em] text-[#000000]">
                {formatCurrency(totalMoneySentToRecipient, currentCurrencyCode)}
              </p>
              <div className="flex items-center py-2">
                <div className="flex items-center bg-[#F4C1C1] p-1 rounded-[10px] mr-3">
                  <img
                    src={require(".././assets/Icons/tred.png")}
                    className="w-[18px] h-[18px] object-contain"
                    alt="wallet"
                  />
                  <span className="ml-2 text-[#FF0000]">20%</span>
                </div>
                <p className="font-normal text-[12px] leading-[14.63px] tracking-[-0.01em] text-[#7C7C7C]">
                  Last 7 Days
                </p>
              </div>
            </div>
            <div className="bg-[#FFF] border-[#D1D1D1] border-[0.8px] rounded-[20px] px-6 py-5 w-full md:w-[281px] h-[319px] flex flex-col mb-4">
              <h4 className="font-[600] text-sm leading-[21.94px] text-[#000000] mb-4">
                Wallet Balance Summary
              </h4>
              <div className="flex items-center gap-6">
                <div className="w-[237px] bg-gradient-to-br from-[#192851] p-4 to-[#93316B] h-[170px] rounded-[12px] mt-5 mb-4">
                  <div className="w-full flex flex-col justify-between h-full bg-[url('./assets/Images/wallet.png')] bg-cover bg-no-repeat rounded-[12px]">
                    <div>
                      <h4 className="font-[500] text-sm mb-1 text-[#FFF]">
                        Current Balance
                      </h4>
                      <div className="flex gap-3 items-center">
                        <h4 className="font-[700] min-w-[50%] text-lg text-[#FFF]">
                          {show
                            ? wallets[currentWallet].amount
                            : " * * * * * *"}
                        </h4>
                        {show ? (
                          <TbEyeOff
                            onClick={() => setShow(false)}
                            color="#FFFFFF"
                            size={"25px"}
                            className="cursor-pointer"
                          />
                        ) : (
                          <TbEye
                            onClick={() => setShow(true)}
                            color="#FFFFFF"
                            className="cursor-pointer"
                            size={"25px"}
                          />
                        )}
                      </div>
                    </div>
                    <h4 className="font-[500] text-xs text-[#FFF]">
                      {wallets[currentWallet].name}
                    </h4>
                  </div>
                </div>
              </div>
              <Select
                value={selectedCurrency}
                onChange={(e) => setSelectedCurrency(e.target.value)}
                className="bg-[#D8E2FF] text-[#000000] h-[33px] rounded-md md:w-[111px]"
              >
                <MenuItem value="Naira">Naira</MenuItem>
                <MenuItem value="Dollar">Dollar</MenuItem>
                <MenuItem value="Pound">Pound</MenuItem>
              </Select>
            </div>
          </div>

          <div className="mb-4 relative">
            <div className="flex flex-col md:flex-row items-center w-full md:w-[760px] ">
              <div className="ml-2  md:w-full">
                <h4 className="font-[600] text-xl text-[#000000] mr-2">
                  Transactions
                </h4>
              </div>
              <div className="flex items-center ">
                <Select
                  value={selectedDateRange}
                  onChange={(e) => setSelectedDateRange(e.target.value)}
                  className="bg-[#FFFFFF] text-[#000000] h-[33px] rounded-lg md:w-[117px]"
                  sx={{
                    backgroundColor: "#FFFFFF",
                    color: "#000000",
                    height: 33,
                    marginRight: "8px",
                    borderRadius: "6px",
                    width: {
                      md: 117,
                    },
                  }}
                >
                  <MenuItem value="7" className="text-sm">
                    Last 7 days
                  </MenuItem>
                  <MenuItem value="14">Last 14 days</MenuItem>
                  <MenuItem value="30">Last 30 days</MenuItem>
                </Select>
                <Button
                  bg={"#192851"}
                  title={"Weekly"}
                  textStyles="!font-[500] !text-[12px] !text-[#000]"
                  mainClasses="!h-[33px] !w-[111px] !bg-[#FFF] !rounded-[6px] mr-2"
                />
                <Button
                  bg={"#192851"}
                  title={"Monthly"}
                  textStyles="!font-[500] !text-[12px] !text-[#000]"
                  mainClasses="!h-[33px] !w-[111px] !bg-[#FFF] !rounded-[6px]"
                />
              </div>
            </div>

            <div className="flex flex-wrap w-full gap-6 items-center">
              <div className="bg-[#FFF] border-[#D1D1D1] border-[0.8px] rounded-[20px] px-4 py-4  flex-1 max-w-[760px] h-[323px] w-full">
                <div className="flex justify-between items-center mb-5">
                  <div className="flex gap-3 items-center"></div>
                </div>
                <IncomeAndExpenditure transactions={transactions} />
              </div>
              <div className="bg-[#FFF] border-[#D1D1D1] border-[0.8px] rounded-[20px] px-4 py-4 mt-4  w-full  md:w-[281px] h-[274px] flex flex-col justify-between">
                <h4 className="font-[600] text-lg text-[#000000]">
                  Order By Device
                </h4>
                <div className="w-[200px] h-[200px] mx-auto">
                  <CustomLabelChart
                    width="100%"
                    height="100%"
                    pieData={[
                      { name: "Mobile", value: 100 },
                      { name: "Desktop", value: 100 },
                      { name: "Tablet", value: 100 },
                    ]}
                    innerradius="38"
                  />
                </div>
                <div className="flex justify-end items-end flex-col w-full mb-4 ">
                  <div className="flex items-center space-x-2 ">
                    <div className="w-[11px] h-[11px] rounded-full bg-[#85B6FF]"></div>
                    <h4 className="font-[400] text-xs text-[#707070]">
                      Mobile
                    </h4>
                  </div>
                  <div className="flex items-center space-x-2">
                    <div className="w-[11px] h-[11px] rounded-full bg-[#4ECB71]"></div>
                    <h4 className="font-[400] text-xs text-[#707070]">
                      Desktop
                    </h4>
                  </div>
                  <div className="flex items-center space-x-2">
                    <div className="w-[11px] h-[11px] rounded-full bg-[#FFBB28]"></div>
                    <h4 className="font-[400] text-xs text-[#707070]">
                      Tablet
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
