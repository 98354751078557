import React, { useEffect, useState } from "react";
import Header from "../../components/layout/Header";
import SideBar from "../../components/layout/SideBar";
import SEO from "../../components/utils/Seo";
import { useFormik } from "formik";
import TextInput from "../../components/common/TextInput";
import SelectInput from "../../components/common/SelectInput";
import CustomTable from "../../components/table/CustomTable";
import { getAllPost } from "../../store/actions/blog/addPost";
import { useDispatch, useSelector } from "react-redux";
import { formHandler } from "../../utils/formhandler";
import Loader from "../../components/utils/Loader";
import Button from "../../components/common/Button";

const AllPost = () => {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state?.blog?.posts?.data) || [];
  const allPublished =
    useSelector((state) => state?.blog?.published?.data) || [];
  const allDraft = useSelector((state) => state?.blog?.draft?.data) || [];
  const [filteredPosts, setFilteredPosts] = useState(posts);
  const [loading, setLoading] = useState(false);

  const form = useFormik({
    initialValues: {
      search: "",
      category: "all",
    },
    onChange: (e) => {
      form.handleChange(e);
      filterPosts(e.target.value);
    },
  });

  const filterPosts = (category) => {
    if (category === "all") {
      setFilteredPosts(posts);
    } else {
      setFilteredPosts(posts.filter((post) => post.category === category));
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getAllPost()).finally(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    filterPosts(form.values.category);
  }, [posts, form.values.category]);

  const categoryOptions = [
    { value: "all", label: "All" },
    { value: "today", label: "Today" },
    { value: "latest", label: "Latest" },
    { value: "featured", label: "Featured" },
    { value: "most-popular", label: "Most Popular" },
  ];

  return (
    <div className="font-mons">
      <SEO
        title={"Blog"}
        description={""}
        name={"Imperial Finance"}
        type={"article"}
      />
      <Header />

      <div className="bg-[#FFFFFF] pt-[72px] font-mons flex flex-col md:flex-row min-h-screen">
        <SideBar />

        <div className="flex-1 pt-4 px-4 md:px-10 bg-[#F1F1F1] relative w-full">
          {loading ? (
            <div className="flex items-center justify-center w-full">
              <Loader />
            </div>
          ) : (
            <div className="w-full md:w-3/4">
              <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0">
                <h1 className="font-semibold text-2xl text-[#000000] mb-2">
                  All Posts
                </h1>
                <div className="flex items-center space-x-4 w-full md:w-auto relative">
                  <TextInput
                    {...formHandler("search", form)}
                    type="text"
                    Icon="search"
                    placeholder="Search for post"
                    mainClasses="w-full md:w-72"
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-between items-center mt-6">
                <div className="w-full md:mb-0 md:w-auto">
                  <span className="text-[#000000] mr-1 text-[#395AB5]">
                    {`All (${posts.length})`}
                  </span>
                  <span className="text-[#000000] mr-1">
                    {`Published (${allPublished.length})`}
                  </span>
                  <span className="text-[#000000]">
                    {`Drafted (${allDraft.length})`}
                  </span>
                </div>

                <div className="flex flex-col md:flex-row items-center md:items-start">
                  <SelectInput
                    name="category"
                    value={form.values.category}
                    onChange={form.handleChange}
                    options={categoryOptions}
                    mainClasses="w-full md:w-34 mb-6 md:mb-0 text-[#000000] mt-1 "
                  />
                  <span className="w-full md:w-34 h-11 border-[0.5px] border-[#999999] rounded-[8px] text-[#000000] p-2 md:ml-4 mt-1">
                    Add New Post
                  </span>
                </div>
              </div>
              <div className="mt-10">
                <CustomTable posts={filteredPosts} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllPost;
