import { createSlice } from "@reduxjs/toolkit";

export const profileSlice = createSlice({
  name: "user",
  initialState: {
    error: null,
    pending: false,
    success: false,
  },

  reducers: {
    logOut: (state, action) => {},
  },
  extraReducers: (builder) => {
    // builder.addCase(completeProfile.pending, (state, action) => {
    //   state.pending = true;
    //   state.error = null;
    // });
    // builder.addCase(completeProfile.fulfilled, (state, action) => {
    //   state.completeProfileStage = action.payload.nextStage;
    //   state.pending = false;
    //   state.success = true;
    //   state.error = null;
    // });
    // builder.addCase(completeProfile.rejected, (state, action) => {
    //   state.pending = false;
    //   state.error = true;
    // });
  },
});

export const {} = profileSlice.actions;
export default profileSlice.reducer;
