import React, { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import SEO from "../components/utils/Seo";
import SideBar from "../components/layout/SideBar";
import TextInput from "../components/common/TextInput";
import { formHandler } from "../utils/formhandler";
import { useFormik } from "formik";
import Button from "../components/common/Button";
import Modal from "../components/modals/Modal";
import { Height } from "@mui/icons-material";
import { changePasswordSchema } from "../utils/validationSchemas";
import { useDispatch } from "react-redux";
import { changePassword } from "../store/actions/onboarding/changePassword";

const Settings = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);

  const handlePasswordChange = async (values, setSubmitting) => {
    const data = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    };

    try {
      const response = await dispatch(
        changePassword({ data, setSubmitting })
      ).unwrap();
      console.log("response", response);
      if (response.success) {
        setModalOpen(true);
      }
    } catch (error) {
      console.error("Password change failed", error);
    }
    setSubmitting(false);
  };

  const form = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: (values, { setSubmitting }) => {
      handlePasswordChange(values, setSubmitting);
    },
  });

  useEffect(() => {
    console.log(form.errors);
  }, [form.errors]);

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="font-mons">
      <SEO
        title={"Settings"}
        description={""}
        name={"Imperial Finance"}
        type={"article"}
      />
      <Header />

      <div className="bg-[#FFFFFF] pt-[72px] font-mons flex min-h-screen">
        <SideBar />

        <div className="flex-1 max-w-full pt-4 px-4 md:px-10 bg-[#F1F1F1] relative w-full">
          <div className="mt-6">
            <h4 className="text-[#000000] text-xl font-semibold leading-[24.38px] tracking-[-0.01em] text-left">
              Password
            </h4>
          </div>

          <div>
            <h4 className=" text-[#000000] text-base font-medium leading-[19.5px] tracking-[-0.01em] mt-6">
              Change password
            </h4>
          </div>

          <div className="mt-8">
            <h4 className="text-[#000000] font-normal">Current Password</h4>

            <TextInput
              {...formHandler("oldPassword", form)}
              type="password"
              showEyeIcon
              placeholder="**************"
              mainClasses={"w-full md:w-[363px]"}
            />
            {form.touched.oldPassword && form.errors.oldPassword && (
              <div className="text-red-500 text-sm">
                {form.errors.oldPassword}
              </div>
            )}
          </div>

          <div className="mt-8">
            <h4 className="text-[#000000] font-normal">New Password</h4>

            <TextInput
              {...formHandler("newPassword", form)}
              type="password"
              showEyeIcon
              placeholder="**************"
              mainClasses={"w-full md:w-[363px]"}
            />
            {form.touched.newPassword && form.errors.newPassword && (
              <div className="text-red-500 text-sm">
                {form.errors.newPassword}
              </div>
            )}
          </div>

          <div className="mt-8">
            <h4 className="text-[#000000] font-normal">Confirm Password</h4>

            <TextInput
              {...formHandler("confirmPassword", form)}
              type="password"
              showEyeIcon
              placeholder="**************"
              mainClasses={"w-full md:w-[363px]"}
            />
            {form.touched.confirmPassword && form.errors.confirmPassword && (
              <div className="text-red-500 text-sm">
                {form.errors.confirmPassword}
              </div>
            )}
          </div>

          <Button
            bg={"#192851"}
            width={"239px"}
            title={"Change Password"}
            onClick={form.handleSubmit}
            disabled={form.isSubmitting || Object.keys(form.errors).length > 0}
            mainClasses={"mt-10 w-[183px]"}
          />
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        width={"375px"}
        Height={"401px"}
      >
        <div className="flex w-full justify-center mt-4">
          <img
            src={require("../assets/Icons/mark.png")}
            className="w-[131px] h-[131px] "
          />
        </div>

        <div className="text-center mt-2">
          <p className="font-semibold text-xl text-[#000000]">
            Password Changed
            <br />
            Successfully
          </p>
          <p className="font-xs font-normal text-sm text-[#000000] mt-2">
            You have successfully change password.
            <br />
            Please use the new password when Sign in.
          </p>
        </div>
        <div className="flex justify-center">
          <Button
            bg={"#192851"}
            width={"239px"}
            title={"Done"}
            mainClasses={"mt-10 w-[183px]"}
            onClick={closeModal}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Settings;
