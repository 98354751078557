export const baseURL = "https://strong.imperialfinanceltd.com/api/v1/";

export const REGISTER = "auth/register";
export const LOGIN = "admin/login";
export const GOOGLE_AUTH = "auth/google?accessToken=";
export const FORGOT_PASSWORD = "auth/forgotPassword";
export const CHANGE_PASSWORD = "admin/changePassword";
export const RESET_PASSWORD = "auth/resetPassword?resetCode=829986";
export const VERIFY_EMAIL = "auth/verify";
export const ADD_CATEGORY = "category/create"
export const GET_CATEGORIES = "category/getAll"
export const GET_ALL_POST = "posts/getAll"
export const DELETE_POST = "posts/delete"
export const GET_ALL_PUBLISHED = "posts/published"
export const GET_ALL_DRAFTED = "posts/drafted"
export const GET_ALL_CUSTOMER = "adminAction/usersMetrics"
export const GET_ALL_TRANSACTION = "adminAction/transactionMetrics"
export const APPROVE_TRANSACTION="adminAction/updateTransaction"
export const GET_ALL_DASHBOARD= "adminAction/dashboardMetrics"
export const ADD_POST = "posts/create"
export const RESEND_TOKEN = "auth/resend";
export const RATE = "rate";

