import React, { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import SideBar from "../components/layout/SideBar";
import SEO from "../components/utils/Seo";
import { FaChevronDown } from "react-icons/fa6";
import TextInput from "../components/common/TextInput";
import { formHandler } from "../utils/formhandler";
import { useFormik } from "formik";
import Modal from "../components/modals/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  approveTransaction,
  getAllTransactions,
} from "../store/actions/transaction/getTransaction";
import { format } from "date-fns";
import { formatCurrency } from "../components/utils/currencyFormatter";
import Loader from "../components/utils/Loader";
import { MenuItem, Select, TextField } from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FaEllipsisV } from "react-icons/fa";

function Transactions() {
  const transactions =
    useSelector((state) => state?.transactions?.transactions?.data) || [];
  const pending = useSelector((state) => state?.transactions?.approvePending);

  console.log("approvePending", pending);

  console.log("transactions", transactions);
  const dispatch = useDispatch();
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedCurrency, setSelectedCurrency] = useState("Select");
  const [selectedStatus, setSelectedStatus] = useState("Select");
  const [menuOpenIndex, setMenuOpenIndex] = useState(null);

  const toggleMenu = (index) => {
    if (menuOpenIndex === index) {
      setMenuOpenIndex(null);
    } else {
      setMenuOpenIndex(index);
    }
  };

  const isMenuOpen = (index) => menuOpenIndex === index;

  const handleApproveTransaction = (reference) => {
    dispatch(approveTransaction({ reference }));
  };

  const itemsPerPage = 7;

  const form = useFormik({
    initialValues: {
      accountNumber: "",
    },
  });

  useEffect(() => {
    setLoading(true);
    dispatch(getAllTransactions()).finally(() => setLoading(false));
  }, [dispatch]);

  const getStatusClass = (status) => {
    switch (status) {
      case "success":
        return "text-[#037400]";
      case "pending":
        return "text-[#FFA500]";
      case "failed":
        return "text-[#FF0000]";
      default:
        return "text-gray-900";
    }
  };

  const formatDate = (dateString) => {
    return `${format(new Date(dateString), "MMM dd, yyyy")}`;
  };

  const formatTime = (dateString) => {
    return `${format(new Date(dateString), "hh:mm a")}`;
  };

  const handleRowClick = (transaction) => {
    setSelectedTransaction(transaction);
    setModalOpen(true);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const isFirstPage = currentPage === 0;
  const isLastPage = (currentPage + 1) * itemsPerPage >= transactions.length;

  const filterTransactions = () => {
    let filtered = transactions;

    if (dateRange[0] && dateRange[1]) {
      const startDate = new Date(dateRange[0]).getTime();
      const endDate = new Date(dateRange[1]).getTime();
      filtered = filtered.filter((transaction) => {
        const transactionDate = new Date(
          transaction.transaction_date
        ).getTime();
        return transactionDate >= startDate && transactionDate <= endDate;
      });
    }

    if (selectedCurrency && selectedCurrency !== "Select") {
      filtered = filtered.filter(
        (transaction) => transaction.currency === selectedCurrency
      );
    }

    if (selectedStatus && selectedStatus !== "Select") {
      filtered = filtered.filter(
        (transaction) => transaction.status === selectedStatus
      );
    }

    if (form.values.accountNumber) {
      filtered = filtered.filter((transaction) =>
        transaction.accountNumber.includes(form.values.accountNumber)
      );
    }

    return filtered;
  };

  const displayedTransactions = filterTransactions().slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <div className="font-mons">
      <SEO
        title={"Transactions"}
        description={""}
        name={"Imperial Finance"}
        type={"article"}
      />
      <Header />

      <div className="bg-[#FFFFFF] pt-[72px] font-mons flex min-h-screen">
        <SideBar />
        <div className="flex-1 max-w-[100vw] overflow-x-auto pt-4 px-4 md:px-10 bg-[#F1F1F1] relative w-full">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0">
            <h1 className="font-semibold text-[20px] leading-[24.38px] tracking-[-0.01em] text-[#000000] mb-2">
              Transactions
            </h1>
            <div className="flex items-center space-x-4 w-full md:w-auto relative">
              <button
                className="bg-[#192851] text-[#ffffff] px-2 rounded-md flex items-center justify-center w-full md:w-[111px] h-[45px] "
                onClick={() => setFilterOpen(!isFilterOpen)}
              >
                Filter
                <FaChevronDown className="ml-2" />
              </button>

              {isFilterOpen && (
                <div className="absolute top-[41px] right-[43px]   md:left-[-153px]  bg-[#ffffff] p-6  shadow-lg rounded-lg mt-2 w-[248px] h-[320px] ">
                  <label className="block text-sm font-medium text-gray-700 mb-1 text-[#000000]">
                    Date range
                  </label>
                  <div className="mb-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateRangePicker
                        value={dateRange}
                        onChange={(newValue) => setDateRange(newValue)}
                        renderInput={(startProps, endProps) => (
                          <>
                            <TextField
                              className="py-0 px-2"
                              {...startProps}
                              variant="outlined"
                              margin="normal"
                            />
                            <TextField
                              {...endProps}
                              variant="outlined"
                              margin="normal"
                            />
                          </>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="mb-2">
                    <label className="block text-sm font-medium text-gray-700 mb-1 text-[#000000]">
                      Currency
                    </label>
                    <Select
                      value={selectedCurrency}
                      onChange={(e) => setSelectedCurrency(e.target.value)}
                      className="bg-[#FFFFFF] text-[#000000] h-[33px] rounded-md md:w-[111px]"
                    >
                      <MenuItem value="Select" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="NGN">Naira</MenuItem>
                      <MenuItem value="USD">Dollar</MenuItem>
                      <MenuItem value="GBP">Pound</MenuItem>
                    </Select>
                  </div>
                  <div className="mb-3">
                    <label className="block text-sm font-medium text-gray-700 mb-1 text-[#000000]">
                      Status
                    </label>
                    <Select
                      value={selectedStatus}
                      onChange={(e) => setSelectedStatus(e.target.value)}
                      className="bg-[#FFFFFF] text-[#000000] h-[33px] rounded-md md:w-[111px]"
                    >
                      <MenuItem value="Select" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="pending">pending</MenuItem>
                      <MenuItem value="success">success</MenuItem>
                      <MenuItem value="failed">failed</MenuItem>
                    </Select>
                  </div>
                  <label className="text-sm font-medium text-gray-700 mb-1 text-[#000000]">
                    Receiver's account number
                  </label>
                  <div className="">
                    {" "}
                    <TextInput
                      {...formHandler("accountNumber", form)}
                      exchange
                      type="number"
                      mainClasses="w-[190px] h-[29px] "
                    />
                  </div>
                </div>
              )}

              <TextInput
                {...formHandler("search", form)}
                type={"text"}
                Icon="search"
                placeholder="Search for transaction reference"
                mainClasses={"w-full md:w-[314px] mb-2"}
              />
            </div>
          </div>

          {loading ? (
            <div className=" w-full flex items-center justify-center">
              <Loader />
            </div>
          ) : (
            <div className="w-full h-full mt-4 overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-[#E7E7E7]">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider font-bold text-[#000000] w-[120px]"
                    >
                      Recipient Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider font-bold text-[#000000] w-[220px]"
                    >
                      Transaction Ref
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider font-bold text-[#000000] w-[190px]"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider font-bold text-[#000000] w-[70px]"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider font-bold text-[#000000] w-[70px]"
                    >
                      Payment Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider font-bold text-[#000000] w-[50px]"
                    ></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {displayedTransactions.map((item, index) => (
                    <tr key={index} onClick={() => handleRowClick(item)}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-[#000000]">
                        Payment to {item.user.fullName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-[#707070]">
                        {item.transaction_ref}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-[#000000]">
                        {formatCurrency(item.amountSent, item.currency)}
                      </td>
                      <td
                        className={`px-6 py-4 whitespace-nowrap text-sm ${getStatusClass(
                          item.status
                        )}`}
                      >
                        {item.status === "success" ? "successful" : item.status}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-[#000000]">
                        {formatDate(item.transaction_date) || "No last login"}
                        <div className="text-[#707070] mt-1">
                          At {formatTime(item.transaction_date) || "No time"}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <div className="relative">
                          <FaEllipsisV
                            className="cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleMenu(index);
                            }}
                          />
                          {isMenuOpen(index) && (
                            <div className="absolute right-0 mt-2 w-[#200] bg-[#ffffff] border rounded shadow-md">
                              <button
                                className="block px-4 py-2 text-sm text-[#4BB543] hover:bg-gray-100"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleApproveTransaction(
                                    item.transaction_ref
                                  );
                                }}
                              >
                                {pending ? "loading...." : "Approve"}
                              </button>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <div className="flex space-x-2 mt-4">
            <button
              className={`bg-transparent border border-[#192851] px-2 py-2 rounded-md flex items-center justify-center w-[78px] h-[30px] text-center ${
                isFirstPage
                  ? "text-[#C0C0C0] border-[#C0C0C0]"
                  : "text-[#192851]"
              }`}
              onClick={handlePreviousPage}
              disabled={isFirstPage}
            >
              Previous
            </button>
            <button
              className={`bg-transparent border border-[#192851] px-2 py-2 rounded-md flex items-center justify-center w-[78px] h-[30px] text-center ${
                isLastPage
                  ? "text-[#C0C0C0] border-[#C0C0C0]"
                  : "text-[#192851]"
              }`}
              onClick={handleNextPage}
              disabled={isLastPage}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        width={"587px"}
        Height={"463px"}
      >
        {selectedTransaction && (
          <div className="p-2">
            <div className="flex justify-between ">
              <span className="font-semibold text-[#000000] text-base">
                Sender
              </span>
              <span className="text-[#000000]">
                {selectedTransaction?.user.fullName}
              </span>
            </div>
            <div className="flex justify-between mt-6">
              <span className="font-semibold text-[#000000] text-base">
                Amount sent
              </span>
              <span className="text-[#000000]">
                {formatCurrency(
                  selectedTransaction?.amountSent,
                  selectedTransaction?.currency
                )}
              </span>
            </div>
            <div className="flex justify-between mt-6">
              <span className="font-semibold text-[#000000] text-base">
                Amount Received by the Recipient:
              </span>
              <span className="text-[#000000]]">
                {formatCurrency(
                  selectedTransaction?.amountReceived,
                  selectedTransaction?.recipientCurrency
                )}
              </span>
            </div>
            <div className="flex justify-between mt-6">
              <span className="font-semibold text-[#000000] text-base">
                Description
              </span>
              <span className="text-[#000000]">
                {selectedTransaction?.description}
              </span>
            </div>
            <div className="flex justify-between mt-6">
              <span className="font-semibold text-[#000000] text-base">
                Transaction Reference:
              </span>
              <span className="font-semibold text-[#000000] text-base">
                Transaction Date:
              </span>
            </div>
            <div className="flex justify-between mt-6">
              <span className="font-normal text-[#000000] text-base">
                {selectedTransaction?.transaction_ref}
              </span>
              <span className="font-normal text-[#000000] text-base">
                {selectedTransaction?.transaction_date}
              </span>
            </div>
            <div className="flex justify-between mt-6">
              <span className="font-semibold text-[#000000] text-base">
                Recipient Name:
              </span>
              <span className="font-normal text-[#000000] text-base">
                {selectedTransaction?.user.fullName}
              </span>
            </div>
            <div className="flex justify-between mt-6">
              <span className="font-semibold text-[#000000] text-base">
                Bank Name
              </span>
              <span className="font-normal text-[#000000] text-base">
                {selectedTransaction?.bankName}
              </span>
            </div>
            <div className="flex justify-between mt-6">
              <span className="font-semibold text-[#000000] text-base">
                Bank Account:
              </span>
              <span className="font-normal text-[#000000] text-base">
                {selectedTransaction?.accountNumber}
              </span>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default Transactions;
