import * as Yup from "yup";

export const signupSchema = Yup.object().shape({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  fullName: Yup.string().required("Required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  confirmPassword: Yup.string()
    .when("password", (password, schema) => {
      if (password) return schema.required("Confirm Password is required");
    })
    .oneOf([Yup.ref("password")], "Passwords must match"),
});
export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),

  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
});

export const rateSchema = Yup.object().shape({
  from: Yup.string().required("From currency is required"),
  to: Yup.string().required("To currency is required"),
  rate: Yup.number()
    .typeError("Rate must be a number")
    .required("Conversion rate is required")
    .positive("Rate must be a positive number")
    .min(0.01, "Rate must be greater than zero"),
});


export const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required('Old password is required'),

  newPassword: Yup.string()
    .required('New password is required')
    .min(8, 'Password must be at least 8 characters'),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Please confirm your new password'),
});

export const addPostSchema = Yup.object().shape({
  title: Yup.string().required("Headline is required"),
  content: Yup.string().required("Content is required"),
  category: Yup.string().required("Category is required"),
  status: Yup.string().oneOf(["drafted", "published"], "Invalid status").required("Status is required"),
});

// export const _createPasswordSchema = Yup.object().shape({
// password: Yup.string()
//   .required("Password is required")
//   .min(8, "Password must be at least 8 characters"),
// password_confirmation: Yup.string()
//   .when("password", (password, schema) => {
//     if (password) return schema.required("Confirm Password is required");
//   })
//   .oneOf([Yup.ref("password")], "Passwords must match"),
// });


// export const _step3Schema = Yup.object().shape({
//   phone: Yup.string()
//     .required("Phone field is required")
//     .test("regex test", `Invalid phone`, (val) => {
//       const regExp = /[a-zA-Z]/;
//       return !regExp.test(val);
//     })

//     .test("empty string test", "Field can not be empty", (val) => {
//       return val.trim() !== "";
//     }),

//   last_name: Yup.string().required("Required"),

//   dob: Yup.date().required("Required"),
//   country: Yup.object().required("Required"),
//   gender: Yup.object().required("Required"),
//   state: Yup.object().required("Required"),
//   city: Yup.object().required("Required"),
//   postal_code: Yup.string()
//     .length(6, "This field requires a six-digit number.")
//     .required("Required"),
//   address: Yup.string().required("Required"),
// });
// export const _supportSchema = Yup.object().shape({
//   first_name: Yup.string().required("Required"),
//   last_name: Yup.string().required("Required"),
//   id: Yup.mixed().required("Required"),
//   dob: Yup.date().required("Required"),

//   gender: Yup.object().required("Required"),
// });
// export const _loginSchema = Yup.object().shape({
//   username: Yup.string()
//     .email("Enter a valid email")
//     .required("Email is required"),
//   password: Yup.string()
//     .required("Password is required")
//     .min(8, "Password must be at least 8 characters"),
// });

