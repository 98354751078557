import React from "react";

const Modal = ({ isOpen, onClose, children, width, height }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-[rgba(0,0,0,0.58)]"
        onClick={onClose}
      ></div>
      <div
        className="relative bg-[#ffffff] rounded-lg shadow-lg z-10 p-4"
        style={{ width: width, height: height }}
      >
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
