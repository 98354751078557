import { createSlice } from "@reduxjs/toolkit";
import { rate } from "../actions/exchange/rate";

export const updateRateSlice = createSlice({
  name: "rate",
  initialState: {
    success: false,
    error: null,
    pending: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(rate.pending, (state) => {
        state.error = null;
        state.pending = true;
      })
      .addCase(rate.fulfilled, (state, action) => {
        state.error = null;
        state.pending = false;
        state.success = true; 
      })
      .addCase(rate.rejected, (state, action) => {
        state.error = action.payload ? action.payload.message : "Failed to update rate";
        state.pending = false;
      });
  },
});

export const {} = updateRateSlice.actions;
export default updateRateSlice.reducer;