import { createAsyncThunk } from "@reduxjs/toolkit";
import { CHANGE_PASSWORD, FORGOT_PASSWORD } from "../../../config/apis";
import { Client } from "../../../config/client";
import { processSuccessMessage } from "../../../utils/processSuccessMessage";
import { proccessErrorMessage } from "../../../utils/proccessErrorMessage";
import { toast } from "react-toastify";

export const changePassword = createAsyncThunk(
  "auth/changepassword",
  async (payload) => {
    const { data, setSubmitting } = payload;


    try {
      const  newData = await Client({
        method: "POST",
        path: CHANGE_PASSWORD,
        data: data,
      });
     
      setSubmitting(false);
      return newData.data
    } catch (error) {
      toast.error(error.response?.data?.message || "Password change failed")
      setSubmitting(false);
      proccessErrorMessage(error);
      throw error;
    }
  }
);
