import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_CATEGORY,
  GET_ALL_POST,
  GET_CATEGORIES,
} from "../../../config/apis";
import { Client } from "../../../config/client";
import { processSuccessMessage } from "../../../utils/processSuccessMessage";
import { proccessErrorMessage } from "../../../utils/proccessErrorMessage";
import { toast } from "react-toastify";

export const addCategory = createAsyncThunk(
  "category/add",
  async (payload, dispatch) => {
    const { data, setSubmitting } = payload;

    try {
      const newData = await Client({
        method: "POST",
        path: ADD_CATEGORY,
        data: data,
      });

      if (newData?.data?.success) {
        toast.success("Category created successfully");
      }
      dispatch(getAllCategories());

      setSubmitting(false);
      return newData.data;
    } catch (error) {
      toast.error(error.response?.data?.message || "Category addition failed");
      setSubmitting(false);
      proccessErrorMessage(error);
      throw error;
    }
  }
);

export const getAllCategories = createAsyncThunk(
  "category/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: GET_CATEGORIES,
      });

      if (response?.data?.success) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      toast.error(error.response?.data?.message);
      proccessErrorMessage(error);
      return rejectWithValue(error.response?.data);
    }
  }
);
