import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/layout/Header";
import SideBar from "../../components/layout/SideBar";
import SEO from "../../components/utils/Seo";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/common/Button";
import { addPost } from "../../store/actions/blog/addPost";
import {
  addCategory,
  getAllCategories,
} from "../../store/actions/blog/addCategory";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { formHandler } from "../../utils/formhandler";
import TextInput from "../../components/common/TextInput";
import { toast } from "react-toastify";
import { addPostSchema } from "../../utils/validationSchemas";

function AddPost() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categories = useSelector((state) => state.blog.categories);
  const [media, setMedia] = useState(null);
  const [mediaPreview, setMediaPreview] = useState(null);
  const [content, setContent] = useState("");
  const fileInputRef = useRef(null);

  const [saveButtonText, setSaveButtonText] = useState("Save Draft");

  const handleMediaChange = (event) => {
    const file = event.target.files[0];
    setMedia(file);
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setMediaPreview(previewUrl);
    }
  };

  const handleDeleteMedia = () => {
    setMedia(null);
    setMediaPreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleResetForm = () => {
    formPost.resetForm();
    setContent("");
    handleDeleteMedia();
  };

  useEffect(() => {
    dispatch(getAllCategories());
    return () => {
      if (mediaPreview) {
        URL.revokeObjectURL(mediaPreview);
      }
    };
  }, [dispatch, mediaPreview]);

  const formPost = useFormik({
    initialValues: {
      title: "",
      content: "",
      category: "",
      status: "published",
    },

    validationSchema: addPostSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const formData = new FormData();
      formData.append("headLine", values.title);
      formData.append("content", content);
      formData.append("category", values.category);
      formData.append("status", values.status);
      if (media) {
        formData.append("media", media);
      }

      try {
        const resultAction = await dispatch(
          addPost({ formData, setSubmitting })
        ).unwrap();

        if (resultAction?.data?.status === "drafted") {
          toast.success("Draft saved");
        } else if (resultAction?.data?.status === "published") {
          toast.success("Post published successfully");
        } else {
          setSaveButtonText("Save Draft");
        }
        // formPost.resetForm();
        // setContent("");
        handleDeleteMedia();
      } catch (error) {
        console.error("Failed to save post:", error);
      }
    },
  });

  return (
    <div className="font-mons">
      <SEO
        title={"Blog"}
        description={""}
        name={"Imperial Finance"}
        type={"article"}
      />
      <Header />

      <div className="bg-[#FFFFFF] pt-[72px] font-mons flex min-h-screen">
        <SideBar />

        <div className="flex-1 max-w-full pt-4 px-4 md:px-10 bg-[#F1F1F1] relative w-full">
          <div className="flex flex-wrap mt-6  w-full">
            <div className="w-full lg:w-3/4 lg:mr-6">
              <div className="flex justify-between mb-2">
                <h1 className="font-semibold text-[20px] leading-[24.38px] tracking-[-0.01em] text-[#000000]">
                  Add New Post
                </h1>
                <button
                  className="text-[#395AB5] font-semibold underline bg-transparent border-none cursor-pointer"
                  disabled={
                    !formPost.isValid ||
                    !formPost.dirty ||
                    formPost.isSubmitting
                  }
                  onClick={() => {
                    formPost.setFieldValue("status", "published");
                    formPost.handleSubmit();
                  }}
                >
                  Publish
                </button>
              </div>

              <div className="mt-6">
                <label
                  className="block text-[#000000] text-sm font-bold mb-2"
                  htmlFor="headline"
                >
                  Headline
                </label>
                <TextInput
                  {...formHandler("title", formPost)}
                  id="headline"
                  type="text"
                  mainClasses="bg-[#ffffff] text-[#000000] w-full h-[63px]  outline-none focus:outline-none"
                  style={{ outline: "none" }}
                  error={formPost.touched.title && formPost.errors.title}
                />
              </div>
              <div className="mb-4">
                <input
                  type="file"
                  id="media"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleMediaChange}
                  accept="image/jpeg, image/png"
                />
                <Button
                  bg={"#192851"}
                  width={"160px"}
                  title={"Add Media"}
                  leftIcon={"cam"}
                  mainClasses={"w-[160px] mt-4"}
                  onClick={() => fileInputRef.current.click()}
                />
                {mediaPreview && (
                  <div className="relative mt-4">
                    <img
                      src={mediaPreview}
                      alt="Media Preview"
                      className="ml-4 w-24 h-24 object-cover  "
                    />
                    <button
                      onClick={handleDeleteMedia}
                      aria-label="Delete media"
                      className="absolute left-[84px] top-0 text-[#FF0000] h-[30px] p-2 flex items-center w-[30px] bg-[#fff] rounded-full"
                    >
                      X
                    </button>
                  </div>
                )}
              </div>
              <div className="">
                <div className="flex justify-between bg-[#E0E0E0] items-center">
                  <label
                    className="text-[#000000] text-sm font-bold mb-2 ml-4 "
                    htmlFor="content"
                  >
                    Paragraph
                  </label>
                  <Button
                    bg={"#999999"}
                    title={formPost.isSubmitting ? "Saving..." : "Save Draft"}
                    width={"120px"}
                    height={"30px"}
                    mainClasses={"text-[#ffffff] bg-[#999999] mr-4"}
                    onClick={() => {
                      formPost.setFieldValue("status", "drafted");
                      formPost.handleSubmit();
                    }}
                  />
                </div>
                <div className="bg-[#ffffff] h-[383px] flex-1">
                  <ReactQuill
                    id="content"
                    className="h-full"
                    style={{ height: "calc(100% - 41px)" }}
                    onChange={(value) => {
                      setContent(value);
                      formPost.setFieldValue("content", value);
                    }}
                  />
                </div>

                <div className="flex justify-between items-center mt-4">
                  <button
                    className="text-[#395AB5] font-semibold underline bg-transparent border-none cursor-pointer"
                    onClick={() => {
                      formPost.setFieldValue("status", "published");
                      formPost.handleSubmit();
                    }}
                  >
                    Preview
                  </button>
                  <Button
                    bg={"#192851"}
                    width={"183px"}
                    leftIcon={"add"}
                    title={"Add New Post"}
                    mainClasses={"mt-5 w-[183px]"}
                    onClick={() => {
                      formPost.handleSubmit();
                      handleResetForm();
                    }}
                  />
                </div>
              </div>
            </div>
            <div className=" w-[223px] bg-[#ffffff] h-[251px]  p-4 mt-8 lg:mt-0">
              <h2 className="font-semibold mb-2 text-[16px] leading-[19.5px] tracking-tighter text-[#000000] ">
                Category
              </h2>

              <div
                className=" mb-2"
                style={{ borderBottom: "0.1px solid #999999" }}
              ></div>

              {Array.isArray(categories.data) &&
                categories.data.map((category) => (
                  <div key={category.id} className="mb-2">
                    <label className="inline-flex items-center ">
                      <input
                        type="radio"
                        className="form-radio"
                        name="category"
                        value={category.category}
                        checked={formPost.values.category === category.category}
                        onChange={formPost.handleChange}
                      />
                      <span className="ml-2 text-[16px] text-[#000000] capitalize">
                        {category.category.replace("-", " ")}
                      </span>
                    </label>
                  </div>
                ))}

              <span
                className="text-blue-600  underline text-[#395AB5] cursor-pointer"
                onClick={() => navigate("/create-category")}
              >
                + Add new category
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddPost;
