import { createSlice } from "@reduxjs/toolkit";
import { getAllCustomer } from "../actions/customer/getCustomer";

export const customerSlice = createSlice({
  name: "customer",
  initialState: {
    success: false,
    error: null,
    pending: false,
    customers: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCustomer.pending, (state) => {
        state.error = null;
        state.pending = true;
      })
      .addCase(getAllCustomer.fulfilled, (state, action) => {
        state.error = null;
        state.pending = false;
        state.success = true; 
        state.customers = action.payload
      })
      .addCase(getAllCustomer.rejected, (state, action) => {
        state.error = action.payload ? action.payload.message : "Failed to update rate";
        state.pending = false;
      });
  },
});

export const {} = customerSlice.actions;
export default customerSlice.reducer;