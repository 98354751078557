import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { format } from "date-fns";
import Header from "../components/layout/Header";
import SideBar from "../components/layout/SideBar";
import SEO from "../components/utils/Seo";
import { FaChevronDown } from "react-icons/fa6";
import TextInput from "../components/common/TextInput";
import { formHandler } from "../utils/formhandler";
import Modal from "../components/modals/Modal";
import { getAllCustomer } from "../store/actions/customer/getCustomer";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/utils/Loader";

function Customers() {
  const dispatch = useDispatch();
  const customers =
    useSelector((state) => state.customers?.customers?.data) || [];

  console.log("customers", customers);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 7;

  const form = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: (values) => {},
  });

  useEffect(() => {
    setLoading(true);
    dispatch(getAllCustomer()).finally(() => setLoading(false));
  }, [dispatch]);

  const handleRowClick = (customer) => {
    setSelectedCustomer(customer);
    setModalOpen(true);
  };

  const formatDate = (dateString) => {
    return `${format(new Date(dateString), "MMM dd, yyyy")}`;
  };

  const formatTime = (dateString) => {
    return `${format(new Date(dateString), "hh:mm a")}`;
  };

  const formatCurrency = (amount, currency) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
    }).format(amount);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const searchQuery = form.values.search.toLowerCase();
  const filteredCustomers = customers.filter((customer) => {
    const fullName = customer.fullName?.toLowerCase() || "";
    const email = customer.email?.toLowerCase() || "";
    return fullName.includes(searchQuery) || email.includes(searchQuery);
  });

  const displayedCustomers = filteredCustomers.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  console.log("disp", displayedCustomers);

  const isFirstPage = currentPage === 0;
  const isLastPage = (currentPage + 1) * itemsPerPage >= customers.length;

  return (
    <div className="font-mons">
      <SEO
        title={"Customers"}
        description={""}
        name={"Imperial Finance"}
        type={"article"}
      />
      <Header />

      <div className="bg-[#FFFFFF] pt-[72px] font-mons flex min-h-screen">
        <SideBar />
        <div className="flex-1 max-w-[100vw] overflow-x-auto pt-4 px-4 md:px-10 bg-[#F1F1F1] relative w-full">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0">
            <h1 className="font-semibold text-[20px] leading-[24.38px] tracking-[-0.01em] text-[#000000] mb-2">
              Customers
            </h1>
            <div className="flex items-center space-x-4 w-full md:w-auto">
              <TextInput
                {...formHandler("search", form)}
                type={"email"}
                Icon="search"
                placeholder="Search for customer"
                mainClasses={"w-full md:w-[314px]"}
              />
            </div>
          </div>

          {loading ? (
            <div className=" w-full flex items-center justify-center">
              <Loader />
            </div>
          ) : (
            <div className="w-full mt-4 overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-[#E7E7E7]">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider font-bold text-[#000000] w-[150px]"
                    >
                      Full Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider font-bold text-[#000000] w-[220px]"
                    >
                      Email Address
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider font-bold text-[#000000] w-[190px]"
                    >
                      Last Login
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider font-bold text-[#000000] w-[150px]"
                    >
                      Date Registered
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {displayedCustomers.map((item, index) => (
                    <tr key={index} onClick={() => handleRowClick(item)}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-[#000000]">
                        {item.fullName || "No name"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-[#707070]">
                        {item.email || "No email"}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-[#000000] ">
                        {formatDate(item.lastLogin) || "No last login"}
                        <div className="text-[#707070] mt-1">
                          At {formatTime(item.lastLogin) || "No time"}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-[#000000] ">
                        {formatDate(item.createdAt) || "No registration date"}
                        <div className="text-[#707070] mt-1 ">
                          At {formatTime(item.createdAt) || "No time"}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <div className="flex space-x-2 mt-4">
            <button
              className={`bg-transparent border border-[#192851] px-2 py-2 rounded-md flex items-center justify-center w-[78px] h-[30px] text-center ${
                isFirstPage
                  ? "text-[#C0C0C0] border-[#C0C0C0]"
                  : "text-[#192851]"
              }`}
              onClick={handlePreviousPage}
              disabled={isFirstPage}
            >
              Previous
            </button>
            <button
              className={`bg-transparent border border-[#192851] px-2 py-2 rounded-md flex items-center justify-center w-[78px] h-[30px] text-center ${
                isLastPage
                  ? "text-[#C0C0C0] border-[#C0C0C0]"
                  : "text-[#192851]"
              }`}
              onClick={handleNextPage}
              disabled={isLastPage}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        width={"568px"}
        Height={"554px"}
      >
        <div className="p-8">
          <div>
            <h2 className="text-lg font-bold mb-4 font-medium text-[#000000]">
              Customer Information
            </h2>
            {selectedCustomer && (
              <div>
                <div className="flex items-center">
                  <img
                    className="w-[12px] h-[15px] object-contain cursor-pointer "
                    src={require("../assets/Icons/user.png")}
                    alt="password"
                  />
                  <span className="ml-3 text-[#707070]">
                    {selectedCustomer.fullName}
                  </span>
                </div>

                <div className="flex items-center mt-3">
                  <img
                    className="w-[12px] h-[15px] object-contain cursor-pointer"
                    src={require("../assets/Icons/mail.png")}
                    alt="password"
                  />
                  <span className="ml-3 text-[#395AB5]">
                    {selectedCustomer.email}
                  </span>
                </div>
                <div className="flex items-center mt-3">
                  <img
                    className="w-[12px] h-[15px] object-contain cursor-pointer"
                    src={require("../assets/Icons/phone.png")}
                    alt="password"
                  />
                  <span className="ml-3 text-[#395AB5]">
                    {selectedCustomer?.profile?.phone}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="mt-20">
            <h2 className="text-lg font-bold mb-4 font-medium text-[#000000]">
              Total Balance In Wallet
            </h2>
            <div className="flex justify-between">
              <span className="text-[#707070]">Naira</span>
              <span className="text-[#707070]">
                {formatCurrency(selectedCustomer.wallet?.nairaBalance, "NGN")}
              </span>
            </div>
            <div className="flex justify-between mt-2">
              <span className="text-[#707070]">Dollar</span>
              <span className="text-[#707070]">
                {formatCurrency(selectedCustomer.wallet?.dollarBalance, "USD")}
              </span>
            </div>
            <div className="flex justify-between mt-2">
              <span className="text-[#707070]">Pound</span>
              <span className="text-[#707070]">
                {formatCurrency(selectedCustomer.wallet?.poundBalance, "GBP")}
              </span>
            </div>
          </div>

          <div className="mt-20">
            <h2 className="text-lg font-bold mb-4 font-medium text-[#000000]">
              Total Transactions
            </h2>
            <div className="flex justify-between">
              <span className="text-[#707070]">Total amount sent (GBP)</span>
              <span className="text-[#707070]">
                {formatCurrency(selectedCustomer?.totalAmountSpent?.GBP, "GBP")}
              </span>
            </div>
            <div className="flex justify-between mt-2">
              <span className="text-[#707070]">Total amount sent (USD)</span>
              <span className="text-[#707070]">
                {formatCurrency(selectedCustomer?.totalAmountSpent?.USD, "USD")}
              </span>
            </div>
            <div className="flex justify-between mt-2">
              <span className="text-[#707070]">Total amount sent (NGN)</span>
              <span className="text-[#707070]">
                {formatCurrency(selectedCustomer?.totalAmountSpent?.NGN, "NGN")}
              </span>
            </div>

            <div className="flex justify-between mt-3">
              <span className="text-[#707070]">
                Total number of transactions
              </span>
              <span className="text-[#707070]">
                {selectedCustomer?.totalNumberOfTransactions}
              </span>
            </div>
          </div>
          <div className="mt-20">
            <h2 className="text-lg font-bold mb-4 font-medium text-[#000000]">
              Recent Transactions
            </h2>
            {selectedCustomer?.transactionHistories?.length === 0 ? (
              <div className="flex justify-center items-center w-full">
                No transaction history yet!
              </div>
            ) : (
              <div>
                <div className="flex justify-between mt-3">
                  <span className="text-[#707070] text-sm font-medium">
                    Payment to John West
                  </span>
                  <span className="text-[#707070] text-sm font-medium">
                    GBP 2,000.00
                  </span>
                  <span className="text-[#707070] text-sm font-medium">
                    May 25, 2024 04:18 am
                  </span>
                </div>
                <div className="flex justify-between mt-3">
                  <span className="text-[#707070] text-sm font-medium">
                    Payment to David Beke
                  </span>
                  <span className="text-[#707070] text-sm font-medium">
                    GBP 1250.00
                  </span>
                  <span className="text-[#707070] text-sm font-medium">
                    May 25, 2024 04:18 am
                  </span>
                </div>
                <div className="flex justify-between mt-3">
                  <span className="text-[#707070] text-sm font-medium">
                    Payment to Kiara Jones
                  </span>
                  <span className="text-[#707070] text-sm font-medium">
                    GBP 850.00
                  </span>
                  <span className="text-[#707070] text-sm font-medium">
                    May 25, 2024 04:18 am
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Customers;
