import React from "react";

const SelectInput = ({
  name,
  value,
  onChange,
  title,
  options,
  disabled,
  mainClasses,
  inputClasses,
  defaultValue,
}) => {
  return (
    <div className={`font-mons w-[367px] ${mainClasses}`}>
      {title && (
        <h4 className="text-[#707070] font-[400] text-[14px] mb-1 leading-[21.94px]">
          {title}
        </h4>
      )}
      <select
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled ? disabled : false}
        className={`flex w-full disabled:cursor-not-allowed bg-transparent text-[13px] pl-4 pr-5 outline-none disabled:bg-black-10:border-error-light transition-all ease-in-out duration-100 h-[45px]  border-[0.5px] border-[#999999] rounded-[8px] ${inputClasses}`}
        defaultValue={defaultValue}
        style={{ backgroundPosition: "calc(100% - 25px)" }}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInput;
