import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { LOGIN } from "../../../config/apis";
import { Client } from "../../../config/client";
import { processSuccessMessage } from "../../../utils/processSuccessMessage";
import { proccessErrorMessage } from "../../../utils/proccessErrorMessage";
import { toast } from "react-toastify";
import { Cookies } from "react-cookie";
import { logOut } from "../../reducer/userSlice"; // Import the logOut action from your auth slice

export const login = createAsyncThunk("auth/login", async (payload, { dispatch }) => {
  const { navigate, data, setSubmitting } = payload;
  try {
    const { data: _data } = await Client({
      method: "POST",
      path: LOGIN,
      data: data,
    });
    console.log("login", _data.token);
    processSuccessMessage(_data?.message);

    const date = new Date();
    const nextDate = date.setMinutes(date.getMinutes() + _data.expiresIn);
    const cookies = new Cookies();

    cookies.set("accessToken", _data.token, {
      expires: new Date(nextDate),
    });

    toast.success("Login Successful");
    setSubmitting(false);
    return _data.user;
  } catch (error) {
    toast.error("Login failed: Check your credentials.");
    proccessErrorMessage(error);
    setSubmitting(false);
    throw error;
  }
});

const TokenExpiryHandler = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get("accessToken");

    if (token) {
      const { exp } = JSON.parse(atob(token.split('.')[1])); 

      const expirationTime = exp * 1000 - Date.now(); 
      const timer = setTimeout(() => {
        dispatch(logOut());
        window.location.reload();
      }, expirationTime);

      return () => clearTimeout(timer);
    }
  }, [dispatch]);

  return null; 
};

export default TokenExpiryHandler;
