import { createSlice } from "@reduxjs/toolkit";
import { dashboard, getDashboard } from "../actions/dashboard/dashboard";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    success: false,
    error: null,
    pending: false,
    dashboard: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboard.pending, (state) => {
        state.error = null;
        state.pending = true;
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.error = null;
        state.pending = false;
        state.success = true; 
        state.dashboard = action.payload
      })
      .addCase(getDashboard.rejected, (state, action) => {
        state.error = action.payload ? action.payload.message : "Failed to update rate";
        state.pending = false;
      });
  },
});

export const {} = dashboardSlice.actions;
export default dashboardSlice.reducer;