import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const TextInput = ({
  value,
  onChange,
  title,
  placeholder,
  disabled,
  mainClasses,
  inputClasses,
  defaultValue,
  showEyeIcon,
  id,
  Icon,
  type,
  name,
  error,
  login,
  exchange,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className={`font-mons  ${mainClasses} relative`}>
      <h4 className="text-[#707070] font-[400] text-[14px] mb-1 leading-[21.94px]">
        {title}
      </h4>

      {Icon ? (
        <img
          src={require(`../../assets/Icons/${Icon}.png`)}
          alt="icon"
          className=" absolute top-5 ml-2 w-5 h-5"
        />
      ) : (
        ""
      )}
      <input
        value={value}
        onChange={onChange}
        id={id}
        name={id}
        type={
          type === "password" && showEyeIcon
            ? passwordVisible
              ? "text"
              : "password"
            : type
        }
        placeholder={placeholder}
        disabled={disabled ? disabled : false}
        style={{
          outline: exchange ? undefined : "none",
          border: exchange ? undefined : "none",
        }}
        className={`placeholder-indent flex w-full disabled:cursor-not-allowed text-[13px] outline-none disabled:bg-black-10 placeholder:text-black-50 focus:border-[2px] focus:border-black-90 invalid:[&:not(:placeholder-shown):not(:focus)]:border-[2px] invalid:[&:not(:placeholder-shown):not(:focus)]:border-error-light transition-all ease-in-out duration-100 h-[45px] bg-[#FFFFFF] border-[0.5px] border-[#999999] rounded-[8px] ${
          Icon === "search" ? "px-8 text-sm" : "px-4"
        } items-center mt-2 text-base ${inputClasses}`}
        defaultValue={defaultValue}
        // onClick={(e) => e.stopPropagation()}
      />
      {type === "password" && showEyeIcon && (
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute top-4 right-0 flex items-center px-2 focus:outline-none px-8"
        >
          {passwordVisible ? <FaEyeSlash /> : <FaEye />}
        </button>
      )}
      {error && <div className="text-[#FF0000] text-sm mt-2">{error}</div>}
    </div>
  );
};

export default TextInput;
