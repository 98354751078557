import { combineReducers } from "@reduxjs/toolkit";
import ui from "./reducer/uiSlice";
import user from "./reducer/userSlice";
import profile from "./reducer/profileSlice";
import rate from "./reducer/updateRateSlice";
import blog from "./reducer/blogSlice";
import transactions from "./reducer/transactionSlice";
import customers from "./reducer/customerSlice";
import dashboard from "./reducer/dashboardSlice";

export const rootReducer = combineReducers({
  ui,
  user,
  profile,
  rate,
  transactions,
  dashboard,
  customers,
  blog,
});
